export const LOADER_VISIBILITY_MLS = 3000
export const TOOLTIP_VISIBILITY_MLS = 3000
export const TABLE_CONTENT_LENGTH = 15

export const CONTRACT_EXPIRY_MAX_LIMIT = 60
export const CONTRACT_EXPIRY_WARNING_LIMIT = 30
export const CONTRACT_EXPIRY_CRITICAL_LIMIT = 0

const chinaEnv = 'china-prod'
export const movementStartedDisableEnvs = ['prod', chinaEnv]
export const elvatorDoorButtonDisableEnvs: string[] = ['prod', chinaEnv]
export const escalatorMovementDisableEnvs: string[] = []
// export const movCompletedFlag = 'MovCompletedFlag'
export const movCompletedFlagDisableEnvs: string[] = ['china-qa', 'prod', chinaEnv]
// export const statDashboardEnvs = ['prod', chinaEnv]

export const DEFAULT_MAX_FILE_SIZE = 200

export const DEFAULT_MAX_FILE_SIZE_LABLE = 'KB'

export const SUPPORTED_FILE_TYPES = ['png', 'svg', 'jpeg', 'jpg', 'gif']

export const DEFAULT_LABEL = 'Upload Files'

export const DEFAULT_HEIGHT = '300px'

export const ErrorInformation = {
  PLEASE_CHOOSE_FILE: 'Please choose a file',
  PLEASE_SELETE_VALID_TYPE_FOR_IMAGE: 'Please select valid file type',
  FILE_SIZE_IS_EXCEED_FOR_IMAGE: `File size is greater than maximum limit`,
  PLEASE_TRY_LATER_FOR_IMAGE: 'Currently we are not able to upload the logo, please try again later',
}
