import styled from 'styled-components'

export const ToolTipContainer = styled.div`
  font-family: Arial, sans-serif;
  font-size: 14px;
  color: #333;
`

export const ToolTipTitle = styled.div`
  font-weight: bold;
  margin-bottom: 8px;
`

export const ToolTipItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 4px;
`

export const ToolTipColor = styled.span<{ color: string }>`
  display: inline-block;
  margin-right: 6px;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  background-color: ${(props) => props.color};
`

export const ToolTipSeriesName = styled.span`
  color: #666;
  font-weight: bold;
  flex-grow: 1;
  margin-right: 10px;
`

export const ToolTipValue = styled.span`
  text-align: right;
`
