import { TitleWrapperProps } from '../components/TitleWrapper'

const subtitleExperienceApis = 'Add elevator call-giving functionalities to your smart building applications'

const subtitleOperationalApis =
  'Integrate information about service orders, call-outs and repairs into your building management system'

const cardTextExperienceApis = 'Contact local KONE sales to improve people flow in your building with Elevator Call API'

const cardTextoperationalApis =
  'Contact local KONE sales to improve operational excellence in your building with Service Info API'

export const KONEAPIsHeroImage = {
  title: 'Explore KONE APIs',
  body: 'Build your personalized, future-proof, smart building',
}

export const KONEApisSharedStrings = {
  availableFeaturesAndData: 'Available features and data',
  versionOne: 'Version 1 delivers two types of responses to you:',
  versionTwo: 'Version 2 delivers three types of responses to you:',
  contactUsButton: 'Contact us',
  createYourSolution: 'Create your solution',
}

export const ExperienceApis = {
  title: 'Experience APIs',
  subtitle: 'Enhance your building’s people flow experience',
  body: `With Experience APIs, you can create new people flow experiences by connecting smart solutions and KONE equipment. Allow a delivery robot to call an elevator and 
  deliver goods between floors or enable elevator calls with your own mobile phone app.`,
}
export const OperationalApis = {
  title: 'Operational APIs',
  subtitle: 'Ensure cost-efficiency and operational excellence',
  body: `With Operational APIs, you can integrate KONE equipment or service operations data with your building management system. This way you can gain easy access to and 
  insights into the service activities done to the equipment.`,
}
export const ElevatorCallApi = {
  title: 'ELEVATOR CALL API',
  subtitle: subtitleExperienceApis,
  body: `<p>With Elevator Call API, you can implement elevator call-giving features in the applications of your choice. Through Elevator Call API, an application can execute 
  elevator calls and receive real-time data about the call and the assigned elevator.</p><p>By integrating the elevator call functionality with a building visitor system, 
  the elevator can be called automatically to the right floor after a visitor sign-in. The API also allows making remote elevator calls quickly and conveniently with a selected 
  mobile application from anywhere in the building. With real-time data, app users can check when the elevator is coming.</p><p>Elevator Call API uses WebSocket API protocol to 
  enable communication between an application, KONE’s systems and equipment.</p>`,
  features: `<p><ul><li>Building information provides basic information of the equipment within the building. This information includes the name of the elevator and which floors 
  the elevator serves.</li><li>Automated elevator calls consist of the commands that can be made through the API. These calls include “elevator from floor X to floor Y” or 
  “elevator to a specific floor”.</li><li>Advanced elevator status information is designed for robots, and provides real-time status information of an elevator. This information 
  includes elevator position (the floor), direction (up or down) and door status (open or closed).</li><li>Call monitoring tells the status of the elevator call that has been made.
  Call status can be one of the following: Allocated / Arriving / Served / Canceled.</li></ul></p>`,
  cardText: cardTextExperienceApis,
}

export const ElevatorCallApiV2 = {
  title: 'ELEVATOR CALL API 2',
  subtitle: subtitleExperienceApis,
  body: `<b>Feature Description: </b> Implement Elevator Call Giving Feature
  
  <p>Elevator Call API- v2, with enhanced features of Elevator Call API v1 can be implemented in the applications of your choice. Through Elevator Call API- v2, 
  an application can execute elevator calls and receive real-time data about the call and the assigned elevator. </p>
  <p><b>Example Solution: </b> Mobile Application</p>
  <p>Find a practical example of an <a href="https://github.com/konecorp/kone-api-examples/tree/main/src/examples/elevator-call-api" target="_blank" rel="noreferrer">
  Elevator Call example project</a> from github.</p>`,
  features: `<p><ul><li>By integrating the elevator call functionality with a building visitor system, the elevator can be called automatically to the right floor after a 
  visitor sign-in. The API also allows making remote elevator calls quickly and conveniently with a selected mobile application from anywhere in the building. With real-time data, 
  app users can check & track the movement of the elevator when the elevator is in motion.</li> <li>Elevator Call API uses WebSocket API protocol to enable communication 
  between an application, KONE systems and equipment.</li> </ul> </p>`,
  cardText: cardTextExperienceApis,
}

export const ServiceRobotApi = {
  title: 'SERVICE ROBOT API',
  subtitle: 'Enable robots to make elevator calls and use elevators autonomously, serving multiple floors',
  body: `<p>Through Service Robot API, you can connect robots with elevators and let them call and operate elevators autonomously. Real-time traffic data about the assigned 
  elevator’s status and direction smooths the robot’s journey.</p><p>With Service Robot API, robots can deliver goods, clean all building floors or guide building visitors from 
  lobby all the way to their destination.</p><p>Service Robot API uses WebSocket API protocol to enable communication between an application, KONE’s systems and equipment.</p>`,
  features: `<p><ul><li>Building information provides basic information of the equipment within the building. This information includes the name of the elevator and which floors 
    the elevator serves.</li><li>Automated elevator calls consist of the commands that can be made through the API. These calls include “elevator from floor X to floor Y” or 
    “elevator to a specific floor”.</li><li>Advanced elevator status information is designed for robots, and provides real-time status information of an elevator. 
    This information includes elevator position (the floor), direction (up or down) and door status (open or closed).</li><li>Call monitoring tells the status of the elevator 
    call that has been made. Call status can be one of the following: Allocated / Arriving / Served / Canceled.</li></ul></p>`,

  cardText: 'Contact local KONE sales to improve robot flow in your building with Service Robot API',
}

export const ServiceRobotApiV2 = {
  title: 'SERVICE ROBOT API 2',
  subtitle: subtitleExperienceApis,
  body: `<b>Functionality</b>: An application can execute robot calls and receive real-time data about the call and the
  assigned elevator
  <li>The API empowers applications to initiate robot calls and gain real-time information about the
  call and the specific elevator assigned, which enhances the robot experience while performing a designated task and the
  application's capabilities.</li>
  <li>The API's primary function is to establish a connection or integration between service robots
  and elevator systems.</li>
  <p>Service Robot API- v2, with enhanced features of Service Robot API v1 can be implemented in the applications of your choice. Through Service Robot API- v2, 
  an application can execute robot calls and receive real-time data about the call and the assigned elevator. </p>
  <p><b>Example Solution: </b> Cleaning Robots</p>
  <p>Find a practical example of a <a href="https://github.com/konecorp/kone-api-examples/tree/main/src/examples/elevator-call-api" target="_blank" rel="noreferrer">
  Service Robot Call example project</a> from github.</p>`,
}

export const EquipmentStatusApi = {
  title: 'EQUIPMENT STATUS API',
  subtitle:
    'Get equipment operating status and service status information, and integrate it into your building management system',
  body: `<p>Equipment Status API allows you to easily integrate equipment (KONE/NON-KONE) and KONE maintenance service information into your chosen building management systems 
  and dashboards. This API makes it possible to collect data under one system instead of using multiple systems.</p>`,
  features: `<p>Through the API, you will get to know information like maintenance service order booked for your equipment that needs a repair, service order related to 
  entrapment or when the technician is on their way and when the work is finished. Easy access to data about your equipment’s service status helps you stay on top of what is 
  happening in your building.</p>`,

  deprecated: '"Deprecated"',
  body_version_one:
    '<p>Equipment Status API uses REST (Representational State Transfer) API protocol to enable data transfer between an application and KONE’s systems.</p>',
  features_version_one: `<p><ul><li>Basic Equipment Information where you get equipment type, serial number and location.</li><li>Equipment Status provides details like service 
  order booked for the equipment that needs a repair or the equipment is operational or not.</li></ul></p>`,

  body_version_two:
    '<p>Equipment Status API 2 uses REST (Representational State Transfer) API protocol to enable data transfer between an application and KONE’s systems.</p>',
  features_version_two: `<p><ul><li>Availability data give high-level state of equipment which describes whether the equipment is currently in use or not. </li><li>Service Status 
  details are received with the event details that are used to determine data of current service status of the equipment.</li><li>Movement data deliver movement information of 
  equipment, in which it indicates whether movement starts or stops.</li></ul></p>`,

  cardText: 'Contact local KONE sales to improve operational excellence in your building with Equipment Status API',
}

export const EquipmentStatusApiApiV2 = {
  title: 'EQUIPMENT STATUS API 2',
  subtitle:
    'Get equipment operating status and service status information, and integrate it into your building management system',
  body: `<b>Functionality</b>: Integrate equipment and Kone maintenance service information into building
  management systems
  <li>The primary function of this API is to facilitate the integration of equipment data (likely
    related to devices and machinery) and maintenance service information provided by Kone (a
    company that provides services related to elevators and escalators) into building management
    systems.</li><br/>
  <p>Equipment Status API 2 uses REST (Representational State Transfer) API protocol and Webhooks mechanism to enable data transfer between an application and 
  KONE’s systems. Data is delivered in three ways: </p>`,
  features: `<p><ul><li><b>Availability Data</b>: Offers a high-level equipment state, indicating if the equipment is in use or not.
  This event supports KONE elevators, escalators, and NON-KONE elevators.</li><li><b>Service Status Data</b>: Provides event details to determine the current service status of the equipment,
  supporting both KONE and NON-KONE elevators.</li><li><b>Movement Data</b>: Delivers information about equipment movement, indicating when movement starts
  or stops, and it supports both KONE and NON-KONE elevators.</li></ul></p>
  <p><b>Example Solution: </b> Building Management System (BMS)</p>
  <p>Find a practical example of an <a href="https://github.com/konecorp/kone-api-examples/tree/main/src/examples/operational-apis/equipment-status-api" target="_blank" rel="noreferrer">
  Equipment Status API</a> from github.</p>`,
  cardText: cardTextExperienceApis,
}

export const ServiceInfoApi = {
  title: 'SERVICE INFO API',
  subtitle: subtitleOperationalApis,
  body: `<p>Service Info API allows you to receive and inspect KONE maintenance service information and integrate it into your chosen building management systems and dashboards. 
  This gives you a continuous, comprehensive view of the maintenance activities of your equipment (KONE/ NON-KONE).</p> `,
  features: `<p><ul><li>Maintenance work information and history - Upcoming and past maintenance, call-out and repair dates, maintenance start and finishing times.
  </li><li>Description of the type of service work.</li><li>Invoice information - Amount from the invoice related to each work order</li></ul></p>`,
  cardText: '',
}

export const ServiceInfoApiOverview = {
  title: 'SERVICE INFO API',
  subtitle: subtitleOperationalApis,
  body: `<p>Service Info API allows you to receive and inspect KONE maintenance service information and integrate it into your chosen building management systems and dashboards. 
  This gives you a continuous, comprehensive view of the maintenance activities of your equipment (KONE/ NON-KONE).</p>`,
  features: `<p>You can access data about active, upcoming and past service activities, descriptions of the work, as well as information about the related invoices. 
  Detailed overview of your building’s maintenance status and improved asset management help you make informed decisions for your building.</p>`,

  body_version_one: `<p>Service Info API uses REST (Representational State Transfer) API protocol to enable data transfer between an application and KONE’s systems.
  </p> <p>Version 1 delivers the following:</p>`,
  features_version_one: `<p><ul><li>Maintenance work information and history - Upcoming and past maintenance, call-out and repair dates, maintenance start and finishing times.
  </li><li>Description of the type of service work.</li><li>Invoice information - Amount from the invoice related to each work order.</li></ul></p>`,

  body_version_two: `<p>Service Info API 2 uses REST (Representational State Transfer) API protocol to enable data transfer between an application and KONE’s systems. 
    It provides more detailed and enhanced service related information.</p><p>Version 2 delivers the  following: </p>`,
  features_version_two: `<p><ul><li>Detailed information related to the equipment service along with its maintenance work information and history.</li><li>Explicit 
  service order related information of the equipment.</li><li>Additional fields to understand service related information.</li></ul></p>`,

  cardText: cardTextoperationalApis,
}

export const ServiceInfoApiV2 = {
  title: 'SERVICE INFO API 2',
  subtitle: subtitleOperationalApis,
  body: `
  <li>The primary function of this API is to receive data related to Kone maintenance services,
  inspect this information and then integrate it into a building management system</li>
  <br/>
  <p><b>API Functionality</b>: <b>Service Info API 2</b> is designed to provide enhanced equipment maintenance
  service information, which users can integrate into their building management systems and dashboards.
  <br/>
  <b>Data Access</b>: It allows users to access data related to active, upcoming and past service activities,
  work descriptions and information about related invoices. This comprehensive data offers insights into
  the maintenance activities of both KONE and NON-KONE equipment.
  <br/>
  <b>API Protocol</b>: The API employs the REST (Representational State Transfer) API protocol for data
  transfer, indicating modern and efficient communication methods.
  <br/>
  <b>Equipment Support</b>: The API is compatible with both KONE and NON-KONE equipment, including
  elevators and escalators, making it versatile for various environments.
  <br/>
  <br/>
  <b>Benefits</b>: Users can gain a detailed overview of their building's maintenance status, leading to
  improved asset management and better decision-making for building maintenance.</p> 
  <p><b>Example Solution: </b> Building Management System (BMS)</p>
  <p>Find a practical example of a <a href="https://github.com/konecorp/kone-api-examples/tree/main/src/examples/operational-apis/service-info-api" target="_blank" rel="noreferrer">
  Service Info API</a> from github.</p>`,
  features: `<p><ul><li>Maintenance work information and history - Upcoming and past maintenance, call-out and repair dates, maintenance start and finishing times.
  </li><li>Description of the type of service work.</li><li>Invoice information - Amount from the invoice related to each work order</li></ul></p>`,
  cardText: cardTextoperationalApis,
}

export const RealtimeMonitoringApi = {
  title: 'REAL-TIME MONITORING API',
  subtitle: 'TODO Lorem ipsum dolor sit amet.',
  body: '<p>TODO Lorem ipsum dolor sit amet.</p>',
  features: '<p><ul><li>TODO Lorem ipsum dolor sit amet.</li></ul></p>',
  cardText: 'TODO Lorem ipsum dolor sit amet.',
}

export const SEOProps: TitleWrapperProps = {
  title: 'Explore KONE APIs – from Elevator Call to Service Info',
  metaTags: [
    {
      name: 'description',
      content:
        'Enhance your building’s people flow experience with Experience APIs or ensure cost-efficiency and operational excellence with Operational APIs',
    },
  ],
}

export const SEOPropsElevatorCallApi: TitleWrapperProps = {
  title: 'KONE Elevator Call API',
  metaTags: [
    {
      name: 'description',
      content:
        'Use KONE Elevator Call API to add elevator call-giving functionalities to your smart building applications.',
    },
  ],
}

export const SEOPropsServiceRobotApi: TitleWrapperProps = {
  title: 'KONE Service Robot API',
  metaTags: [
    {
      name: 'description',
      content: 'Enable robots to make elevator calls and use elevators autonomously with Service Robot API.',
    },
  ],
}

export const SEOPropsEquipmentStatusApi: TitleWrapperProps = {
  title: 'KONE Equipment Status API',
  metaTags: [
    {
      name: 'description',
      content: 'Get equipment operating status and service status information with our KONE Service Robot API.',
    },
  ],
}

export const SEOPropsServiceInfoApi: TitleWrapperProps = {
  title: 'KONE Service Info API',
  metaTags: [
    {
      name: 'description',
      content:
        'With KONE Service API, you can integrate information about service orders, call-outs and repairs into your building management system.',
    },
  ],
}
