import React, { FC, useEffect, useState, useContext } from 'react'
import { Link, useParams, useHistory } from 'react-router-dom'

import dummyPartner from '../../../assets/icons_add_new.svg'
import 'react-quill/dist/quill.snow.css'
import { CreateFormStrings, PartnerStrings } from '../../../shared/strings/PartnersContent'
import { ContactDetail, Video, Document } from '../Models'

import {
  BackButton,
  ButtonContainer,
  CategoryTierContainer,
  CategoryTierContainerItem,
  CompanyHeader,
  CompanyHeading,
  CompanyLink,
  CompanyLogo,
  CompanyMiddle,
  CompanyMiddleItem,
  CompanyUpper,
  ContactTitle,
  Container,
  DescP,
  DescSection,
  EditHeadingIcon,
  FormLabel,
  GlobeIcon,
  HeaderContainer,
  LabelContainer,
  LogoSection,
  MainContainer,
  ContactLower,
  ContactContent,
  ContactHeaderTitle,
  PartnerName,
  SaveButton,
  SelectContainer,
  StyledLine,
  TypeSelector,
  SolutionContainer,
  SolutionLower,
  SolutionHeaderTitle,
  SolutionContent,
  ContactContainer,
  RegionContainer,
  RegionContent,
  CountryListOL,
  SalesSupportContainer,
  SalesSupportListOUL,
  CompanyTitle,
  SupportLangHeader,
  EditDetailLink,
  LinkContainer,
  LinkHeading,
  LinkBodyTitle,
  VideoIcon,
  DocumentIcon,
} from './Styles'
import Modal from '../../../shared/components/ModalPopUp'
import EditCompanyInfo from '../EditPartner/EditCompanyInfo'
import EditContactInfo from '../EditPartner/EditContactInfo'
import { Col, Row } from 'antd'
import { CommonAPI, SalesPageAPI } from '../../../shared/api/salespage'
import { useQuery, useQueryClient } from 'react-query'
import EditSolutionInfo from '../EditPartner/EditSolutionInfo'
import { Toast } from '../../../shared/components'
import EditIntegratorLinks from '../EditPartner/EditIntegratorLinks'
import { Type } from '../../../shared/models/salespage'
import { PartnerContext } from '../AddNewParnter/state'
import dompurify from 'dompurify'

const sanitizer = dompurify.sanitize

const PartnerDetails: FC = () => {
  const [isEditSolutionOpen, setIsEditSolutionOpen] = useState(false)
  const [isEditLinkOpen, setIsEditLinkOpen] = useState(false)
  const [isEditCustomerSegmentOpen, setIsEditCustomerSegmentOpen] = useState(false)
  const [isEditCountryOpen, setIsEditCountryOpen] = useState(false)
  const [isEditApisOpen, setIsEditApisOpen] = useState(false)
  const [isEditLanguageOpen, setIsEditLanguageOpen] = useState(false)
  const [isEditCompanyInfoOpen, setIsEditCompanyInfoOpen] = useState(false)
  const [isEditContactInfoOpen, setIsEditContactInfoOpen] = useState(false)
  const [isCreateErrorVisible, setIsCreateErrorVisible] = useState(false)
  const [createIntegratorErrorMessage, setCreateIntegratorErrorMessage] = useState<string | ''>()
  const history = useHistory()
  const [isSuccess, setIsSuccess] = useState<boolean | false>(false)
  const [successMsg, setSuccessMsg] = useState<string | ''>('')
  const { partnerId } = useParams<{ partnerId: string }>()
  const { isLoading, error, data } = useQuery(SalesPageAPI.queryNames.GET_PARTNER_DETIALS, () =>
    SalesPageAPI.getPartnerDetails(partnerId)
  )
  const getMasterData = useQuery([CommonAPI.commonQueryName.GET_MASTER_DATA], () => CommonAPI.getMasterData())
  const { masterData, setMasterData } = useContext(PartnerContext)

  const queryClient = useQueryClient()

  const onBack = () => {
    history.push('/dashboard/partners')
  }

  const invalidateIntegratorData = () => {
    queryClient.invalidateQueries({ queryKey: [SalesPageAPI.queryNames.GET_PARTNER_DETIALS] })
  }

  useEffect(() => {
    if (getMasterData?.data) {
      setMasterData(getMasterData.data)
    }
  }, [getMasterData?.data])

  const getLanguages = (language: string[]) => {
    if (language?.length === 0) {
      return []
    }
    const selectedLang: string[] = []
    masterData?.language?.map((oneLanguage: any) => {
      if (language?.indexOf(oneLanguage?.value) !== -1) {
        selectedLang.push(oneLanguage?.label)
      }
    })
    return selectedLang
  }

  const getCountries = (countries: string[]) => {
    const selectedCountry: string[] = []
    masterData?.country?.map((oneCountry: Type) => {
      if (countries?.indexOf(oneCountry?.value) !== -1) {
        selectedCountry.push(oneCountry?.label)
      }
    })
    return selectedCountry
  }

  const getOneCountries = (country: string) => {
    const selectedCountry: string[] = []
    masterData?.country?.map((oneCountry: any) => {
      if (oneCountry.value == country) {
        selectedCountry.push(oneCountry.label)
      }
    })
    return selectedCountry
  }

  const getCategory = (categories: string[]) => {
    const selectedCategory: string[] = []
    masterData?.category?.map((oneCategory: Type) => {
      if (categories?.indexOf(oneCategory?.value) !== -1) {
        selectedCategory.push(oneCategory?.label)
      }
    })
    return selectedCategory
  }

  const getKeySegmentsContent = (keySegments: string[]) => {
    const selectedKeysegmnts: string[] = []
    masterData?.segments?.map((oneSegment: Type) => {
      if (keySegments?.indexOf(oneSegment?.value) !== -1) {
        selectedKeysegmnts.push(oneSegment?.label)
      }
    })
    return selectedKeysegmnts
  }

  const getAPIs = (apis: string[]) => {
    const selectedApis: string[] = []
    masterData?.api?.map((oneApi: Type) => {
      if (apis?.indexOf(oneApi?.value) !== -1) {
        selectedApis.push(oneApi?.label)
      }
    })
    return selectedApis
  }

  const showContactsContent = () => {
    return (
      <>
        <ContactLower>
          <Row gutter={24}>
            <Col span={4}>
              <ContactHeaderTitle>{PartnerStrings.firstName}</ContactHeaderTitle>
            </Col>
            <Col span={4}>
              <ContactHeaderTitle>{PartnerStrings.lastName}</ContactHeaderTitle>
            </Col>
            <Col span={6}>
              <ContactHeaderTitle>{PartnerStrings.email}</ContactHeaderTitle>
            </Col>
            <Col span={4}>
              <ContactHeaderTitle>{PartnerStrings.phoneNumber}</ContactHeaderTitle>
            </Col>
            <Col span={4}>
              <ContactHeaderTitle>{PartnerStrings.jobTitle}</ContactHeaderTitle>
            </Col>
          </Row>
          {data?.contactInfo?.map((contact: any, index: number) => (
            <Row key={`contact_key_${index}`} gutter={24}>
              <Col span={4}>
                <ContactContent>{contact?.firstName}</ContactContent>
              </Col>
              <Col span={4}>
                <ContactContent>{contact?.lastName}</ContactContent>
              </Col>
              <Col span={6}>
                <ContactContent>{contact?.email}</ContactContent>
              </Col>
              <Col span={4}>
                <ContactContent>{contact?.phoneNumber}</ContactContent>
              </Col>
              <Col span={4}>
                <ContactContent>{contact?.jobTitle}</ContactContent>
              </Col>
            </Row>
          ))}
        </ContactLower>
      </>
    )
  }

  const closeEditSolution = () => {
    setIsEditSolutionOpen(false)
  }

  const closeEditCustomerSegments = () => {
    setIsEditCustomerSegmentOpen(false)
  }

  const closeEditCountry = () => {
    setIsEditCountryOpen(false)
  }

  const closeEditApis = () => {
    setIsEditApisOpen(false)
  }

  const closeEditLanguage = () => {
    setIsEditLanguageOpen(false)
  }

  const closeEditCompanyInfo = () => {
    setIsEditCompanyInfoOpen(false)
  }

  const closeEditContactInfo = () => {
    setIsEditContactInfoOpen(false)
  }

  const closeEditIntegratorLinks = () => {
    setIsEditLinkOpen(false)
  }

  const onClickEditSolution = () => {
    setIsEditSolutionOpen(true)
  }

  const onClickEditContactInfo = () => {
    setIsEditContactInfoOpen(true)
  }

  const onClickEditCompanyInfo = () => {
    setIsEditCompanyInfoOpen(true)
  }

  const onClickEditLink = () => {
    setIsEditLinkOpen(true)
  }

  const filterOption = (input: any, option: any) => {
    if (option.children) {
      return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ? true : false
    } else if (option.label) {
      return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ? true : false
    }
    return false
  }

  const getSolutionContent = () => {
    return (
      <>
        <SolutionLower>
          <Row gutter={24}>
            <Col span={3}>
              <SolutionHeaderTitle>{PartnerStrings.solutionName}</SolutionHeaderTitle>
            </Col>
            <Col span={5}>
              <SolutionHeaderTitle>{PartnerStrings.solutionHighlights}</SolutionHeaderTitle>
            </Col>
            <Col span={4}>
              <SolutionHeaderTitle>{PartnerStrings.solutionCategory}</SolutionHeaderTitle>
            </Col>
            <Col span={4}>
              <SolutionHeaderTitle>{PartnerStrings.keyCustomerSegments}</SolutionHeaderTitle>
            </Col>
            <Col span={4}>
              <SolutionHeaderTitle>{PartnerStrings.apiUsed}</SolutionHeaderTitle>
            </Col>
            <Col span={4}>
              <SolutionHeaderTitle>{PartnerStrings.country}</SolutionHeaderTitle>
            </Col>
          </Row>
          {data?.solutionInfo?.map((solution: any, index: number) => (
            <Row key={`solution_key_${index}`} gutter={24}>
              <Col span={3}>
                <SolutionContent>{solution?.solutionName}</SolutionContent>
              </Col>
              <Col span={5}>
                <SolutionContent
                  dangerouslySetInnerHTML={{ __html: sanitizer(solution?.solutionHighlights) }}
                ></SolutionContent>
              </Col>
              <Col span={4}>
                <SolutionContent>{getCategory(solution?.category)?.join(',')}</SolutionContent>
              </Col>
              <Col span={4}>
                <SolutionContent>{getKeySegmentsContent(solution?.keyCustomerSegments)?.join(', ')}</SolutionContent>
              </Col>
              <Col span={4}>
                <SolutionContent>{getAPIs(solution?.apis)?.join(', ')}</SolutionContent>
              </Col>
              <Col span={4}>
                <SolutionContent>{getCountries(solution?.country)?.join(', ')}</SolutionContent>
              </Col>
            </Row>
          ))}
        </SolutionLower>
      </>
    )
  }

  const getLinkContent = () => {
    return (
      <>
        <SolutionLower>
          <Row gutter={2}>
            <Col span={10}>
              <LinkHeading>{PartnerStrings.videoTitle}</LinkHeading>
            </Col>
            <Col span={10}>
              <LinkHeading>{PartnerStrings.documentTitle}</LinkHeading>
            </Col>
          </Row>
          <Row>
            <Col span={10}>
              {data?.partnerInfo?.document?.videos?.map((data: Video, index: number) => (
                <Row key={`fileName${index}`} gutter={2} wrap={false}>
                  <Col span={20}>
                    <LinkBodyTitle>
                      {' '}
                      <VideoIcon style={{ marginRight: '5px', marginLeft: '5px' }} />
                      {data?.fileName}
                    </LinkBodyTitle>
                  </Col>
                </Row>
              ))}
            </Col>
            <Col span={10}>
              {data?.partnerInfo?.document?.documents?.map((data: Document, index: number) => (
                <Row key={`fileName${index}`} gutter={2} wrap={false}>
                  <Col span={20}>
                    <LinkBodyTitle>
                      {' '}
                      <DocumentIcon style={{ marginBottom: '-6px' }} />
                      {data?.fileName}
                    </LinkBodyTitle>
                  </Col>
                </Row>
              ))}
            </Col>
          </Row>
        </SolutionLower>
      </>
    )
  }

  const getContent = () => {
    return (
      <Container>
        <Toast
          isVisible={isCreateErrorVisible}
          text={createIntegratorErrorMessage}
          onDismiss={() => setIsCreateErrorVisible(false)}
          type="error"
          allowWithTime={true}
        />
        <Toast
          isVisible={isSuccess}
          text={successMsg}
          onDismiss={() => setIsSuccess(false)}
          type="success"
          allowWithTime={true}
        />
        <HeaderContainer>
          <BackButton onClick={() => onBack()} type="primary">
            Back
          </BackButton>
          <PartnerName>{data?.partnerInfo?.partnerName}</PartnerName>
        </HeaderContainer>
        <MainContainer>
          <CompanyHeader>
            <CompanyHeading>{PartnerStrings.companyInfo}</CompanyHeading>
            <EditDetailLink onClick={onClickEditCompanyInfo}>
              <EditHeadingIcon />
              {PartnerStrings.edit}
            </EditDetailLink>
          </CompanyHeader>
          <StyledLine />
          <CompanyUpper>
            <LogoSection>
              <CompanyLogo src={data?.partnerInfo?.image || dummyPartner} />
              <CompanyLink>
                {data?.partnerInfo?.websiteLink && (
                  <>
                    <GlobeIcon />
                    <Link to={data.partnerInfo.websiteLink}>{data.partnerInfo.websiteLink}</Link>
                  </>
                )}
              </CompanyLink>
            </LogoSection>
            <DescSection>
              <CategoryTierContainer>
                <CategoryTierContainerItem>
                  <CompanyTitle>{`About ${data?.partnerInfo?.partnerName}`}</CompanyTitle>
                </CategoryTierContainerItem>
                <CategoryTierContainerItem>
                  <DescP>{data?.partnerInfo?.companyInfo}</DescP>
                </CategoryTierContainerItem>
              </CategoryTierContainer>
            </DescSection>
          </CompanyUpper>
          <SolutionContainer>
            <CompanyHeader>
              <CompanyHeading>{PartnerStrings.IntegratorSolution}</CompanyHeading>
              <EditDetailLink onClick={onClickEditSolution}>
                <EditHeadingIcon />
                {PartnerStrings.edit}
              </EditDetailLink>
            </CompanyHeader>
            <StyledLine />
            {getSolutionContent()}
          </SolutionContainer>
          <ContactContainer>
            <CompanyHeader>
              <CompanyHeading>{PartnerStrings.contactDetails}</CompanyHeading>
              <EditDetailLink onClick={onClickEditContactInfo}>
                <EditHeadingIcon />
                {PartnerStrings.edit}
              </EditDetailLink>
            </CompanyHeader>
            <StyledLine />
            {showContactsContent()}
          </ContactContainer>
          <RegionContainer>
            <SupportLangHeader>
              <CompanyHeading>{PartnerStrings.countryRegionAvlForDistribution}</CompanyHeading>
            </SupportLangHeader>
            <StyledLine />
            <RegionContent>
              <CountryListOL>
                {getOneCountries(data?.partnerInfo?.country)?.map((country: string, index: number) => (
                  <li key={`country_key12_${index}`}>{country}</li>
                ))}
              </CountryListOL>
            </RegionContent>
          </RegionContainer>
          <SalesSupportContainer>
            <SupportLangHeader>
              <CompanyHeading>{PartnerStrings.supportLanguageText}</CompanyHeading>
            </SupportLangHeader>
            <StyledLine />
            <SalesSupportListOUL>
              {getLanguages(data?.partnerInfo?.language)?.map((language, index: number) => (
                <li key={`support_lang_key_${index}`}>{language}</li>
              ))}
            </SalesSupportListOUL>
          </SalesSupportContainer>
          <LinkContainer>
            <CompanyHeader>
              <CompanyHeading>{PartnerStrings.editIntegratorLinks}</CompanyHeading>
              <EditDetailLink onClick={onClickEditLink}>
                <EditHeadingIcon />
                {PartnerStrings.edit}
              </EditDetailLink>
            </CompanyHeader>
            <StyledLine />
            {getLinkContent()}
          </LinkContainer>
        </MainContainer>
      </Container>
    )
  }
  return (
    <>
      {isLoading ? 'Loading Integrator Data' : getContent()}
      <Modal
        key="edit-custom-segments"
        isOpen={isEditCustomerSegmentOpen}
        title={PartnerStrings.editCustomerSegments}
        onClose={closeEditCustomerSegments}
      >
        <SelectContainer>
          <LabelContainer>
            <FormLabel htmlFor="customer-segment-js" id="customer-segment-label-js">
              {CreateFormStrings.keyCustomerSegments}*
            </FormLabel>
          </LabelContainer>
          <TypeSelector
            showSearch
            id="customer-segment-selector-js"
            defaultValue={CreateFormStrings.customerSegments}
            disabled={false}
            getPopupContainer={(trigger: any) => trigger.parentNode}
            optionFilterProp="children"
            filterOption={filterOption}
          ></TypeSelector>
        </SelectContainer>
        <ButtonContainer>
          <SaveButton>{PartnerStrings.saveChanges}</SaveButton>
        </ButtonContainer>
      </Modal>

      <Modal
        key="edit-country"
        isOpen={isEditCountryOpen}
        title={PartnerStrings.editCountry}
        onClose={closeEditCountry}
      >
        <SelectContainer>
          <LabelContainer>
            <FormLabel htmlFor="country-js" id="country-label-js">
              {CreateFormStrings.selectCountry}*
            </FormLabel>
          </LabelContainer>
          <TypeSelector
            showSearch
            id="country-selector-js"
            defaultValue={CreateFormStrings.selectCountry}
            disabled={false}
            getPopupContainer={(trigger: any) => trigger.parentNode}
            optionFilterProp="children"
            filterOption={filterOption}
          ></TypeSelector>
        </SelectContainer>
        <ButtonContainer>
          <SaveButton>{PartnerStrings.saveChanges}</SaveButton>
        </ButtonContainer>
      </Modal>

      <Modal key="edit-apis-1" isOpen={isEditApisOpen} title={PartnerStrings.editApis} onClose={closeEditApis}>
        <SelectContainer>
          <LabelContainer>
            <FormLabel htmlFor="apis-js" id="apis-label-js">
              {CreateFormStrings.selectApi}*
            </FormLabel>
          </LabelContainer>
          <TypeSelector
            showSearch
            id="apis-selector-js"
            defaultValue={CreateFormStrings.selectApi}
            disabled={false}
            getPopupContainer={(trigger: any) => trigger.parentNode}
            optionFilterProp="children"
            filterOption={filterOption}
          ></TypeSelector>
        </SelectContainer>
        <ButtonContainer>
          <SaveButton>{PartnerStrings.saveChanges}</SaveButton>
        </ButtonContainer>
      </Modal>

      <Modal
        key="edit-apis-2"
        isOpen={isEditLanguageOpen}
        title={PartnerStrings.editLanguages}
        onClose={closeEditLanguage}
      >
        <SelectContainer>
          <LabelContainer>
            <FormLabel htmlFor="edit-languages-js" id="edit-languages-label-js">
              {CreateFormStrings.selectLanguages}*
            </FormLabel>
          </LabelContainer>
          <TypeSelector
            showSearch
            id="edit-languages-selector-js"
            defaultValue={CreateFormStrings.selectLanguages}
            disabled={false}
            getPopupContainer={(trigger: any) => trigger.parentNode}
            optionFilterProp="children"
            filterOption={filterOption}
          ></TypeSelector>
        </SelectContainer>
        <ButtonContainer>
          <SaveButton>{PartnerStrings.saveChanges}</SaveButton>
        </ButtonContainer>
      </Modal>
      {isEditLinkOpen && (
        <EditIntegratorLinks
          isEditIntegratorLinkOpen={isEditLinkOpen}
          closeIntegratorLink={closeEditIntegratorLinks}
          integratorInfo={data?.partnerInfo?.document}
          partnerId={data?.partnerInfo?.partnerId}
          invalidateIntegratorData={invalidateIntegratorData}
        />
      )}
      <EditCompanyInfo
        integratorInfo={data?.partnerInfo}
        isEditCompanyInfoOpen={isEditCompanyInfoOpen}
        closeEditCompanyInfo={closeEditCompanyInfo}
        isCreateErrorVisible={isCreateErrorVisible}
        setIsCreateErrorVisible={setIsCreateErrorVisible}
        createIntegratorErrorMessage={createIntegratorErrorMessage}
        setCreateIntegratorErrorMessage={setCreateIntegratorErrorMessage}
        invalidateIntegratorData={invalidateIntegratorData}
      />
      {isEditContactInfoOpen && (
        <EditContactInfo
          isEditContactInfoOpen={isEditContactInfoOpen}
          closeEditContactInfo={closeEditContactInfo}
          isCreateErrorVisible={isCreateErrorVisible}
          setIsCreateErrorVisible={setIsCreateErrorVisible}
          createIntegratorErrorMessage={createIntegratorErrorMessage}
          setCreateIntegratorErrorMessage={setCreateIntegratorErrorMessage}
          contactInfo={data?.contactInfo}
          invalidateIntegratorData={invalidateIntegratorData}
          setIsSuccess={setIsSuccess}
          setSuccessMsg={setSuccessMsg}
        />
      )}
      {isEditSolutionOpen && (
        <EditSolutionInfo
          isEditSolutionInfoOpen={isEditSolutionOpen}
          closeEditSolutionInfo={closeEditSolution}
          isCreateErrorVisible={isCreateErrorVisible}
          setIsCreateErrorVisible={setIsCreateErrorVisible}
          createIntegratorErrorMessage={createIntegratorErrorMessage}
          setCreateIntegratorErrorMessage={setCreateIntegratorErrorMessage}
          solutionInfo={data?.solutionInfo}
          invalidateIntegratorData={invalidateIntegratorData}
          setIsSuccess={setIsSuccess}
          setSuccessMsg={setSuccessMsg}
        />
      )}
    </>
  )
}

export default PartnerDetails
