import { Menu } from 'antd'
import { isArray } from 'lodash'
import React, { FC, useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { ReactComponent as ApiDocumentationIcon } from '../../assets/icons-api-documentation.svg'
import { ReactComponent as DevGuideIcon } from '../../assets/icons-dev-guide.svg'
import { ReactComponent as FeedbackIcon } from '../../assets/icons-feedback.svg'
import { ReactComponent as ReleaseNotesIcon } from '../../assets/icons-release-notes.svg'
import { ReactComponent as KoneApisIcon } from '../../assets/icons-kone-apis.svg'
import { useAuth } from '../../shared/contexts/authContext'
import Paths from '../Paths'
import { MenuContainer } from './Styles'

const { SubMenu, Item, Divider } = Menu

type Keys =
  | React.Key[]
  | {
      key: React.Key
      item: React.ReactInstance
      trigger: string
      open: boolean
    }

function extractAllPaths(path: string): Map<string, boolean> {
  const pathParts = path.split('/')
  const allPaths: Map<string, boolean> = new Map()

  let prefix = ''

  for (let i = 0; i < pathParts.length; i++) {
    prefix = prefix === '/' ? `${prefix}${pathParts[i]}` : `${prefix}/${pathParts[i]}`
    allPaths.set(prefix, i === pathParts.length - 1)
  }

  // avoid highlighting "Dashboard" menu item (which has "/dashboard" path) in case if submenu item is selected
  if (pathParts.length > 2) {
    allPaths.delete(Paths.Dashboard.path)
  }

  return allPaths
}

function toPurePaths(paths: Map<string, boolean>): string[] {
  return Array.from(paths.keys())
}

const DashboardSidebarMenu: FC = () => {
  const { currentUser, currentOrganization } = useAuth()
  const location = useLocation()
  const [selectedKeys, setSelectedKeys] = useState(new Map())

  const onOpenChange = (keys: Keys) => {
    if (isArray(keys)) {
      /**
       * Two cases:
       *  - If user opens a submenu the new paths is longer than existing.
       *    The last part is the section opened so just add that to the selected paths.
       *  - If user closes a submenu look what of the selected paths went away
       */
      const newPaths = new Map(selectedKeys)
      if (keys.length > selectedKeys.size) {
        newPaths.set(keys[keys.length - 1].toString(), false)
      } else {
        Array.from(newPaths.keys())
          .filter((path) => !keys.includes(path))
          .forEach((key) => newPaths.delete(key))
      }

      setSelectedKeys(newPaths)
    }
  }

  useEffect(() => {
    // Clone old paths
    const newPaths = new Map(selectedKeys)

    // Remove existing leaf path
    const [path] = Array.from(newPaths.entries()).find(([_, isLeaf]) => isLeaf) || ['', false]
    if (path === Paths.ElevatorWebsocketAPIRobots.path) {
      newPaths.delete(Paths.ElevatorWebsocketAPI.path)
    }
    if (path === Paths.ElevatorWebsocketAPIRobotsV2.path) {
      newPaths.delete(Paths.ElevatorWebsocketAPIV2.path)
    }

    newPaths.delete(path)

    // Add all the new paths
    extractAllPaths(location.pathname).forEach((isLeaf: boolean, path: string) => {
      newPaths.set(path, isLeaf)
    })

    // Set new paths
    setSelectedKeys(newPaths)
  }, [location.pathname])

  const documentationItems = [
    <SubMenu title={Paths.DeveloperGuide.name} key={Paths.DeveloperGuide.path} icon={<DevGuideIcon />}>
      <Item id="first-api-call-menuitem-js" key={Paths.MyFirstAPICall.path}>
        <Link to={Paths.MyFirstAPICall.path}>{Paths.MyFirstAPICall.name}</Link>
      </Item>

      {/* <Item id="overview-api-menuitem-js" key={Paths.IntroductionAPI.path}>
      <Link to={Paths.IntroductionAPI.path}>{Paths.IntroductionAPI.name}</Link>
    </Item> */}
      <SubMenu title={Paths.SolutionCreation.name}>
        <Item id="solution-api-menuitem-js" key={Paths.SolutionOverview.path}>
          <Link to={Paths.SolutionOverview.path}>{Paths.SolutionOverview.name}</Link>
        </Item>
        <Item id="solution-development" key={Paths.SolutionDevelopment.path}>
          <Link to={Paths.SolutionDevelopment.path}>{Paths.SolutionDevelopment.name}</Link>
        </Item>
        <Item id="solution-validation" key={Paths.SolutionValidation.path}>
          <Link to={Paths.SolutionValidation.path}>{Paths.SolutionValidation.name}</Link>
        </Item>
        <Item id="solution-deployment" key={Paths.SolutionDeployment.path}>
          <Link to={Paths.SolutionDeployment.path}>{Paths.SolutionDeployment.name}</Link>
        </Item>
      </SubMenu>
      {/* <Item id="sandbox-access-menuitem-js" key={Paths.SandboxApplication.path}>
      <Link to={Paths.SandboxApplication.path}>{Paths.SandboxApplication.name}</Link>
    </Item> */}

      {/* <Item id="events-docs-menuitem-js" key={Paths.Events.path}>
      <Link to={Paths.Events.path}>{Paths.Events.name}</Link>
    </Item> */}

      {/* <Item id="production-access-menuitem-js" key={Paths.ProductionAccess.path}>
      <Link to={Paths.ProductionAccess.path}>{Paths.ProductionAccess.name}</Link>
    </Item> */}
      <Item id="glossary-menuitem-js" key={Paths.Glossary.path}>
        <Link to={Paths.Glossary.path}>{Paths.Glossary.name}</Link>
      </Item>
    </SubMenu>,
    <SubMenu title={Paths.KoneAPIs.name} key={Paths.KoneAPIs.path} icon={<KoneApisIcon />}>
      <Item id="elevator-call-api-menuitem-js" key={Paths.ElevatorCallAPI.path}>
        <Link to={Paths.ElevatorCallAPI.path}>{Paths.ElevatorCallAPI.name}</Link>
      </Item>
      <Item id="service-robot-api-menuitem-js" key={Paths.ServiceRobotAPI.path}>
        <Link to={Paths.ServiceRobotAPI.path}>{Paths.ServiceRobotAPI.name}</Link>
      </Item>
      <Item id="equipment-status-api-product-menuitem-js" key={Paths.EquipmentStatusAPIProduct.path}>
        <Link to={Paths.EquipmentStatusAPIProduct.path}>{Paths.EquipmentStatusAPIProduct.name}</Link>
      </Item>
      <Item id="service-info-api-product-menuitem-js" key={Paths.ServiceInfoAPIProduct.path}>
        <Link to={Paths.ServiceInfoAPIProduct.path}>{Paths.ServiceInfoAPIProduct.name}</Link>
      </Item>
    </SubMenu>,
    <SubMenu title={Paths.APIDocumentation.name} key={Paths.APIDocumentation.path} icon={<ApiDocumentationIcon />}>
      <Item id="elevator-websocket-api-2-menuitem-js" key={Paths.ElevatorWebsocketAPIV2.path}>
        <Link to={Paths.ElevatorWebsocketAPIV2.path}>{Paths.ElevatorWebsocketAPIV2.name}</Link>
      </Item>

      {/* Equipment Status*/}
      <SubMenu title={Paths.EquipmentStatusAPI2Product.name} key={Paths.EquipmentStatusAPI2Product.path}>
        <Item id="equipment-status-2-rest-api-docs-js" key={Paths.EquipmentStatus2RestAPIDocs.path}>
          <Link to={Paths.EquipmentStatus2RestAPIDocs.path}>{Paths.EquipmentStatus2RestAPIDocs.name}</Link>
        </Item>
        <SubMenu
          style={{ marginLeft: '-22px' }}
          title={Paths.EquipmentStatus2WebhookDocs.name}
          key={Paths.EquipmentStatus2WebhookDocs.path}
        >
          <Item id="equipment-status-2-webhook-events-docs-js" key={Paths.EquipmentStatus2WebhookEvents.path}>
            <Link to={Paths.EquipmentStatus2WebhookEvents.path}>{Paths.EquipmentStatus2WebhookEvents.name}</Link>
          </Item>
          <Item id="webhook-management-api-menuitem-js" key={Paths.WebhookManagementAPIDocs.path}>
            <Link to={Paths.WebhookManagementAPIDocs.path}>{Paths.WebhookManagementAPIDocs.name}</Link>
          </Item>
          <Item id="webhook-callback-api-menuitem-js" key={Paths.WebhookCallbackAPIDocs.path}>
            <Link to={Paths.WebhookCallbackAPIDocs.path}>{Paths.WebhookCallbackAPIDocs.name}</Link>
          </Item>
        </SubMenu>
      </SubMenu>

      {/* Service Info */}
      <SubMenu title={Paths.ServiceInfoAPIV2Product.name}>
        <Item id="service-info-api-v2-docs-menuitem-js" key={Paths.ServiceInfoAPIV2Docs.path}>
          <Link to={Paths.ServiceInfoAPIV2Docs.path}>{Paths.ServiceInfoAPIV2Docs.name}</Link>
        </Item>
        <Item id="service-info-webhook-menuitem-js" key={Paths.ServiceInfoWebhookAPIDocs.path}>
          <Link to={Paths.ServiceInfoWebhookAPIDocs.path}>{Paths.ServiceInfoWebhookAPIDocs.name}</Link>
        </Item>
      </SubMenu>

      <Item id="authentication-api-2-menuitem-js" key={Paths.AuthenticationAPIV2.path}>
        <Link to={Paths.AuthenticationAPIV2.path}>{Paths.AuthenticationAPIV2.name}</Link>
      </Item>

      {/* Depreciated */}
      {currentUser?.isKoneAdmin && (
        <SubMenu title={Paths.Deprecated.name} key={Paths.Deprecated.path}>
          <Item id="authentication-api-menuitem-js" key={Paths.AuthenticationAPI.path}>
            <Link to={Paths.AuthenticationAPI.path}>{Paths.AuthenticationAPI.name}</Link>
          </Item>
          <Item id="building-api-menuitem-js" key={Paths.BuildingAPI.path}>
            <Link to={Paths.BuildingAPI.path}>{Paths.BuildingAPI.name}</Link>
          </Item>
          <Item id="elevator-websocket-api-menuitem-js" key={Paths.ElevatorWebsocketAPI.path}>
            <Link to={Paths.ElevatorWebsocketAPI.path}>{Paths.ElevatorWebsocketAPI.name}</Link>
          </Item>
          <Item id="equipment-status-api-docs-menuitem-js" key={Paths.EquipmentStatusAPIDocs.path}>
            <Link to={Paths.EquipmentStatusAPIDocs.path}>{Paths.EquipmentStatusAPIDocs.name}</Link>
          </Item>
          <Item id="service-info-api-docs-menuitem-js" key={Paths.ServiceInfoAPIDocs.path}>
            <Link to={Paths.ServiceInfoAPIDocs.path}>{Paths.ServiceInfoAPIDocs.name}</Link>
          </Item>
        </SubMenu>
      )}
    </SubMenu>,
  ]

  const getKoneAdminMenuItems = () => {
    return (
      <>
        <Item id="organizations-menuitem-js" key={Paths.Organizations.path}>
          <Link to={Paths.Organizations.path}>{Paths.Organizations.name}</Link>
        </Item>
        <SubMenu title={Paths.Resources.name} key={Paths.Resources.path}>
          <Item id="buildings-menuitem-js" key={Paths.Buildings.path}>
            <Link to={Paths.Buildings.path}>{Paths.Buildings.name}</Link>
          </Item>
          <Item id="equipments-menuitem-js" key={Paths.Equipments.path}>
            <Link to={Paths.Equipments.path}>{Paths.Equipments.name}</Link>
          </Item>
          <Item id="device-mappings-js" key={Paths.DeviceMappings.path}>
            <Link to={Paths.DeviceMappings.path}>{Paths.DeviceMappings.name}</Link>
          </Item>
        </SubMenu>
        <Item id="users-menuitem-js" key={Paths.Users.path}>
          <Link to={Paths.Users.path}>{Paths.Users.name}</Link>
        </Item>
        <Item id="partners-menuitem-js" key={Paths.Partners.path}>
          <Link to={Paths.Partners.path}>{Paths.Partners.name}</Link>
        </Item>
        <Item id="statistics-menuitem-js" key={Paths.Statistics.path}>
          <Link to={Paths.Statistics.path}>{Paths.Statistics.name}</Link>
        </Item>
        <Divider />
        {documentationItems.map((item) => item)}
        <Item id="release-notes-menuitem-js" key={Paths.ReleaseNotes.path} icon={<ReleaseNotesIcon />}>
          <Link to={Paths.ReleaseNotes.path}>{Paths.ReleaseNotes.name}</Link>
        </Item>
      </>
    )
  }

  const getNonKoneAdminMenuItems = () => {
    return (
      <>
        <Item id="applications-menuitem-js" key={Paths.Dashboard.path}>
          <Link to={Paths.Dashboard.path}>{Paths.Dashboard.name}</Link>
        </Item>
        {currentOrganization &&
          (currentOrganization.type.toLowerCase() === 'personal' ? null : (
            <Item id="organization-details-menuitem-js" key={Paths.OrganizationDetails.path}>
              <Link to={Paths.OrganizationDetails.path}>{Paths.OrganizationDetails.name}</Link>
            </Item>
          ))}
        <Divider />
        {documentationItems.map((item) => item)}
        <Item id="release-notes-menuitem-js" key={Paths.ReleaseNotes.path} icon={<ReleaseNotesIcon />}>
          <Link to={Paths.ReleaseNotes.path}>{Paths.ReleaseNotes.name}</Link>
        </Item>
        <Item id="feedback-menuitem-js" key={Paths.Feedback.path} icon={<FeedbackIcon />}>
          <Link to={Paths.Feedback.path}>{Paths.Feedback.name}</Link>
        </Item>
      </>
    )
  }

  const getMenuItems = () => {
    if (currentUser?.isKoneAdmin) {
      return getKoneAdminMenuItems()
    } else {
      return getNonKoneAdminMenuItems()
    }
  }

  return (
    <MenuContainer>
      <Menu
        mode="inline"
        selectedKeys={toPurePaths(selectedKeys)}
        openKeys={toPurePaths(selectedKeys)}
        onOpenChange={onOpenChange}
      >
        {getMenuItems()}
      </Menu>
    </MenuContainer>
  )
}

export default DashboardSidebarMenu
