import { Col, Row } from 'antd'
import React, { FC } from 'react'
import { H4, P } from '../../../../shared/components/Typography'
import { PayloadProperty, StyledLine } from '../../components/PayloadProperty'
import { CodeBlock, LeftCol, Paragraph, RightCol, SectionRow, StyledEventName } from '../../Styles'
import styled from 'styled-components'
import { NavHashLink } from 'react-router-hash-link'

export const Table = styled.div`
  th,
  td {
    border-bottom: 1px solid #474242;
    padding: 10px;
    text-align: left;
  }
`
const groupIdMessage = 'groupId can be integer. Refer MultiGroup in Glossary'
export const MonitoringRequests: FC = () => {
  return (
    <SectionRow>
      <LeftCol span="12">
        <H4 id="monitoring-commands">Site Monitoring</H4>
        <Row>
          <Col span={5}>
            <StyledEventName>site-monitoring</StyledEventName>
          </Col>
          <Col span={19}>
            <P size="small">
              <p>
                Site monitoring events contain the real time information about the lift status, call state, deck
                position, door state etc. Clients can subscribe to the interested events using the specific topic for
                each event. A topic describes the type of the event and allows to mention specific states.
              </p>
            </P>

            <br />
          </Col>
        </Row>
        <Row>
          <Col span="24">
            <PayloadProperty
              name="type"
              type="string"
              required={true}
              description={`Message type to subscribe for the events to monitor the lift status and call state`}
              stringEnums={['site-monitoring']}
            />
            <PayloadProperty
              name="requestId"
              type="string"
              required={true}
              description={`Unique request ID to pair response to correct request`}
              example={`01841d1c-f4ba-4f9c-a348-6f679bfae86e`}
            />
            <PayloadProperty
              name="callType"
              type="string"
              required={true}
              description={`call type to get active the monitoring`}
              stringEnums={[`monitor`]}
              example={`monitor`}
            />
            <PayloadProperty
              name="buildingId"
              type="string"
              required={true}
              description={`Unique identifier for the building`}
              format={`building:BUILDING_ID`}
              example={`building:100000123`}
            />
            <PayloadProperty
              name="groupId"
              type="string"
              required={true}
              description={`Unique identifier for the group`}
              example={`"1"`}
              comment={groupIdMessage}
            />
            <PayloadProperty name="payload" type="object" required={true} />
            <Row style={{ marginLeft: '60px' }}>
              <Col span="22">
                <PayloadProperty
                  name="sub"
                  type="string"
                  required={true}
                  description={`Unqiue identifier for the subscriber. ClientID can also be used here`}
                  example={`123abc456def`}
                />
                <PayloadProperty
                  name="duration"
                  type="number"
                  required={true}
                  description={`Duration of the subscription in seconds. Min: 0 Max: 300s Default: 300. Subscriber has to re-activate the topic before this time runs out.`}
                  example={`100`}
                />
                <PayloadProperty
                  name="subTopics"
                  type="string array"
                  required={true}
                  description={`List of topics to subscribe`}
                  format={`[call_state/session_id/state]`}
                  example={`[call_state/123/fixed]`}
                />
              </Col>
            </Row>
            <p>Available sub topics to monitor</p>
            <p>
              <NavHashLink smooth to="#action">
                ACTION{' '}
              </NavHashLink>
              <br />
            </p>
            TOPIC: action/[area]/[terminal] - action/1000/1
            <StyledLine />
            <p>
              <NavHashLink smooth to="#lift-status">
                LIFT STATUS{' '}
              </NavHashLink>
              <br />
            </p>
            TOPIC: lift_&#60;liftID&#62;/status
            <StyledLine />
            <p>
              <NavHashLink smooth to="#next-stop-eta">
                LIFT NEXT STOP ETA
              </NavHashLink>
              <br />
            </p>
            TOPIC: lift_&#60;liftID&#62;/next_stop_eta
            <StyledLine />
            <p>
              <NavHashLink smooth to="#lift-deck-position">
                LIFT DECK POSITION
              </NavHashLink>
              <br />
            </p>
            TOPIC: lift_&#60;liftID&#62;/position
            <StyledLine />
            <p>
              <NavHashLink smooth to="#lift-stopping">
                LIFT STOPPING
              </NavHashLink>
            </p>
            TOPIC: lift_&#60;liftID&#62;/stopping
            <StyledLine />
            <p>
              <NavHashLink smooth to="#door-state">
                LIFT DOOR STATE
              </NavHashLink>
              <br />
            </p>
            TOPIC: lift_&#60;liftID&#62;/doors
            <StyledLine />
            <p>
              <NavHashLink smooth to="#call-state">
                CALL STATE
              </NavHashLink>
              <br />
            </p>
            TOPIC: call_state/[session_id]/[state] - call_state/123/fixed
            <br />
            <br />
            <Row id="Available-call-states">
              <NavHashLink smooth to="#Available-call-states">
                Available call states :
              </NavHashLink>
            </Row>
            <Table>
              <table className="table table-bordered table-hover table-condensed">
                <thead>
                  <tr>
                    <th>Status</th>
                    <th>Destination</th>
                    <th>Landing</th>
                    <th>Car</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>call is waiting for allocation</td>
                    <td>registered</td>
                    <td>registered</td>
                    <td>registered</td>
                  </tr>
                  <tr>
                    <td>subgroup has been locked</td>
                    <td>being_assigned</td>
                    <td>being_assigned</td>
                    <td> </td>
                  </tr>
                  <tr>
                    <td>call has been sent to lift</td>
                    <td>assigned</td>
                    <td>assigned</td>
                    <td> </td>
                  </tr>
                  <tr>
                    <td>allocation is locked</td>
                    <td>being_fixed</td>
                    <td>being_fixed</td>
                    <td>being_served</td>
                  </tr>
                  <tr>
                    <td>slowing down to source</td>
                    <td>fixed</td>
                    <td>fixed</td>
                    <td> </td>
                  </tr>
                  <tr>
                    <td>doors opening at source</td>
                    <td>being_served</td>
                    <td>served</td>
                    <td> </td>
                  </tr>
                  <tr>
                    <td>slowing down to destination</td>
                    <td>served_soon</td>
                    <td> </td>
                    <td>served_soon</td>
                  </tr>
                  <tr>
                    <td>doors opening at destination</td>
                    <td>served</td>
                    <td> </td>
                    <td>served</td>
                  </tr>
                  <tr>
                    <td>cancelled</td>
                    <td>cancelled</td>
                    <td>cancelled</td>
                    <td>cancelled</td>
                  </tr>
                </tbody>
              </table>
            </Table>
          </Col>
        </Row>
      </LeftCol>
      <RightCol span="12">
        <Paragraph>Request example for monitoring</Paragraph>

        <CodeBlock language="json">
          {`{
   "type": "site-monitoring",
   "requestId": "01841d1c-f4ba-4f9c-a348-6f679bfae86e",
   "buildingId": "targetBuildingId",
   "callType": "monitor",
   "groupId": "1",
   "payload": { 
     "sub": "smartOfficeLiftSouthWing_user123", 
     "duration": 300, 
     "subtopics": ["call_state/123/fixed"] 
   }
}`}
        </CodeBlock>
        <Paragraph>Acknowledgement response</Paragraph>

        <CodeBlock language="json">
          {`{
  "connectionId": "PzIhJfgdjoECG1A=",
  "requestId": "01841d1c-f4ba-4f9c-a348-6f679bfae86e",
  "statusCode": 201,
  "data": {
    "time": "2022-04-08T10:27:08.272Z"
 }
}`}
        </CodeBlock>

        <Paragraph>Request payload validation error</Paragraph>

        <CodeBlock language="json">
          {`{
  "type": "error",
  "connectionId": "ZLOG3c0SDoECFnQ=",
  "requestId": "01841d1c-f4ba-4f9c-a348-6f679bfae86e",
  "statusCode": 400,
  "data": { 
    "error": "'callType' is required" 
  }
}`}
        </CodeBlock>
      </RightCol>
      <LeftCol span="12">
        <H4 id="monitoring-events-js">Monitoring Events</H4>

        <Row id="action">
          <Col span={5}>
            <StyledEventName>action</StyledEventName>
          </Col>
          <Col span={19}>
            <P size="small">
              Event happens when an action is performed on a specific area and terminal. For e.g to understand if an API
              call is made to a specific destination
            </P>

            <br />
          </Col>
        </Row>
        <Row>
          <Col span="24">
            <PayloadProperty
              name="subTopic"
              type="string"
              required={true}
              description={`Subscribed subTopic`}
              example={`action/1000/1`}
              format={`action/area/terminal`}
            />
            <PayloadProperty
              name="requestId"
              type="string"
              required={true}
              description={`Unique request ID to pair response to correct request`}
              example={`01841d1c-f4ba-4f9c-a348-6f679bfae86e`}
            />
            <PayloadProperty
              name="callType"
              type="string"
              required={true}
              description={`Site monitoring call type`}
              stringEnums={['monitor']}
            />
            <PayloadProperty
              name="buildingId"
              type="string"
              required={true}
              description={`Unique identifier for the building`}
              format={`building:BUILDING_ID`}
              example={`building:9990000951`}
            />
            <PayloadProperty
              name="groupId"
              type="string"
              required={true}
              description={`Unique identifier for the group`}
              example={`"1"`}
              comment={groupIdMessage}
            />
            <PayloadProperty name="data" type="object" required={true} />
            <Row style={{ marginLeft: '60px', width: '90%' }}>
              <Col span="22">
                <PayloadProperty
                  name="callType"
                  type="string"
                  required={true}
                  description={`For lift calls, call type action is needed`}
                  stringEnums={['action']}
                />
                <PayloadProperty
                  name="buildingId"
                  type="string"
                  required={true}
                  description={`Unique identifier for the building`}
                  format={`building:BUILDING_ID`}
                  example={`building:9990000951`}
                />
                <PayloadProperty
                  name="groupId"
                  type="string"
                  required={true}
                  description={`Unique identifier for the group`}
                  example={`"1"`}
                  comment={groupIdMessage}
                />
                <PayloadProperty name="call" type="object" required={true} />
                <PayloadProperty
                  name="time"
                  type="string"
                  required={true}
                  description={`ISO formatted timestamp of the message`}
                  format={`yyyy-MM-dd'T'HH:mm:ss.SSSZ`}
                  example={`2022-03-28T08:17:09.163Z`}
                />
                <PayloadProperty
                  name="area"
                  type="number"
                  description={`Area id where the call was made, if any`}
                  example={`3000`}
                />
                <PayloadProperty
                  name="terminal"
                  type="number"
                  required={true}
                  description={`Terminal ID where the action is made at`}
                  example={`1`}
                />
                <PayloadProperty
                  name="user_id"
                  type="string"
                  description={`User Id in hex string provided by Access Control system`}
                  example={`ab1376ef`}
                />
                <PayloadProperty name="call" type="object" required={true} />
                <Row style={{ marginLeft: '60px', width: '100%' }}>
                  <Col span="22">
                    <PayloadProperty
                      name="session_id"
                      type="number"
                      required={true}
                      description={`Session Id from the the original call response. Removes the call from the system if possible`}
                    />

                    <PayloadProperty
                      name="action"
                      type="number"
                      description={`Action Id for landing calls, destination calls and car calls`}
                      required={true}
                      example={`22`}
                    />
                    <PayloadProperty
                      name="destination"
                      type="number"
                      description={`Destination area if given action supports destination calls or car calls`}
                      example={`7000`}
                    />

                    <PayloadProperty name="group_size" type="number" description={`Default: 1`} example={`1`} />
                  </Col>
                </Row>
                <PayloadProperty
                  name="success"
                  type="boolean"
                  required={true}
                  description={`Result of the call request.`}
                  example={`true`}
                />
                <PayloadProperty name="error" type="string" description={`Error enum if the call is not successful`} />
                <PayloadProperty
                  name="origin"
                  type="string"
                  required={true}
                  description={`Origin, from where the action originated. Device, API etc`}
                  example={`DEVICE`}
                />
                <PayloadProperty
                  name="modified_area"
                  type="number"
                  description={`If for some reason the source area is changed, new source is given to user. This can only happen with very special features, 
                  so no regular client needs to worry about it or be prepared for such thing to ever happen.`}
                  example={`1010`}
                />
                <PayloadProperty
                  name="modified_destination"
                  type="number"
                  description={`If for some reason the destination area is changed, new destination is given to user. This happens with all transfer calls`}
                  example={`15010`}
                />
                <PayloadProperty
                  name="modified_reason"
                  type="string"
                  description={`If destination has been modified due to manual transfer, user should exit at modified destination and make a new call there. 
                  If transfer is automatic, there will be an event posted with second session_id and ID of the group serving this transferred call. 
                  So user should exit the lift, then follow the second allocation to their next lift for next part of journey. As a note, DLA modifies the call later, 
                  so that info is not available here.`}
                  example={`AutomaticTransfer`}
                />{' '}
              </Col>
            </Row>
          </Col>
        </Row>
      </LeftCol>
      <RightCol span="12">
        <Paragraph>Event for action example</Paragraph>

        <CodeBlock language="json">
          {`{
  "subtopic": "action/1000/1",
  "requestId": "760da6e6-a232-448e-a8a0-a4d6e5173fa1",
  "callType": "monitor",
  "buildingId": "building:9990000951",
  "groupId": "1",
  "data" : {
    "time": "2022-01-28T08:17:09.163Z",     
    "area": 2000,                           
    "terminal": 3,                          
    "user_id": "ab1376ef",                  
    "call": {                               
      "session_id": 24387,         
      "action": 2001,                       
      "destination": 7000,                  
      "group_size": 1                       
    },
    "success": true,                        
    "error": "REASON",                      
    "origin": "DEVICE",                     
    "allocation_type": "Immediate",    
    "modified_area": 1010,                  
    "modified_destination": 15010,   
    "modified_reason": "AutomaticTransfer"
 }
}`}
        </CodeBlock>
      </RightCol>
      <LeftCol span="12">
        <Row id="lift-status">
          <Col span={5}>
            <StyledEventName>lift-status</StyledEventName>
          </Col>
          <Col span={19}>
            <P size="small">
              Occurs when there is an update in the lift status such as deck area, lift mode and if there is any fault
              active etc.
            </P>
          </Col>
        </Row>
        <Row>
          <Col span="24">
            <PayloadProperty
              name="subTopic"
              type="string"
              required={true}
              description={`Subscribed subTopic`}
              format={`lift_LIFT_ID/status`}
              example={`lift_1/status`}
            />
            <PayloadProperty
              name="requestId"
              type="string"
              required={true}
              description={`Unique request ID to pair response to correct request`}
              example={`01841d1c-f4ba-4f9c-a348-6f679bfae86e`}
            />
            <PayloadProperty
              name="callType"
              type="string"
              required={true}
              description={`Site monitoring call type`}
              stringEnums={['monitor']}
            />
            <PayloadProperty
              name="buildingId"
              type="string"
              required={true}
              description={`Unique identifier for the building`}
              format={`building:BUILDING_ID`}
              example={`building:9990000951`}
            />
            <PayloadProperty
              name="groupId"
              type="string"
              required={true}
              description={`Unique identifier for the group`}
              example={`"1"`}
              comment={groupIdMessage}
            />
            <PayloadProperty name="data" type="object" required={true} />
            <Row style={{ marginLeft: '60px', width: '90%' }}>
              <Col span="22">
                <PayloadProperty
                  name="time"
                  type="string"
                  required={true}
                  description={`ISO formatted timestamp of the message`}
                  format={`yyyy-MM-dd'T'HH:mm:ss.SSSZ`}
                  example={`2022-03-28T08:17:09.163Z`}
                />
                <PayloadProperty
                  name="recreated"
                  type="boolean"
                  description={`Event is recreated due to user subscription or resubscription to status event`}
                  example={`false`}
                />

                <PayloadProperty
                  name="fault_active"
                  type="boolean"
                  description={`Active lift fault status if any`}
                  example={`false`}
                />

                <PayloadProperty
                  name="lift_mode"
                  type="number"
                  required={true}
                  description={`Current lift mode to know if the lift is in normal operating mode or service mode etc. -1 indicates lift is in out of service`}
                  example={'12'}
                  stringEnumDescriptions={[
                    {
                      value: '0',
                      description: `Lift is normal mode`,
                    },
                    {
                      value: '1',
                      description: `The lift has been switched to inspection drive by maintence personnel`,
                    },
                    {
                      value: '2',
                      description: `The lift has been switched to fireman’s service`,
                    },
                    {
                      value: '3',
                      description: `The lift is running under emergency power supply`,
                    },
                    {
                      value: '4',
                      description: `The lift is controlled manually by an attendant in the car`,
                    },
                    {
                      value: '5',
                      description: `The lift is used for freight service, and it is not available for normal passengers`,
                    },
                    {
                      value: '6',
                      description: `The lift is reserved for special VIP service, and it is not available for normal passengers`,
                    },
                    {
                      value: '7',
                      description: `The lift is switched to out of service and does not operate`,
                    },
                    {
                      value: '8',
                      description: `The lift is switched to independent service, and it is controlled only with car call buttons`,
                    },
                    {
                      value: '9',
                      description: `The lift has received a hospital emergency hall call and will only serve that call. No other passenger requests are accepted`,
                    },
                    {
                      value: '10',
                      description: `The lift is in hospital bed service mode. Only one landing call at a time is served and each landing call gets an empty car`,
                    },
                    {
                      value: '11',
                      description: `The lift is serving a high priority landing call and does not accept any other hall calls before the priority call has been served`,
                    },
                    {
                      value: '12',
                      description: `As PRH, except that each priority call is assigned to an empty car`,
                    },
                    {
                      value: '13',
                      description: `This option is designed to allow one specified lift to be removed from normal group service to answer a separate set of landing call buttons in full collective mode`,
                    },
                    {
                      value: '14',
                      description: `Lift has failed and is unable to drive. More information of the reason can be found faults`,
                    },
                    {
                      value: '15',
                      description: `The lift has been switched to special parking mode. It drives normally, except that it is always parked at a special floor when it becomes free. It is disconnected from normal parking operations`,
                    },
                    {
                      value: '16',
                      description: `A procedure to dispatch lifts in abnormal situations. The lift is controlled manually from inside the car. It is not serving normal passengers`,
                    },
                    {
                      value: '17',
                      description: `The lift has been disconnected from landing calls with a switch in the control panel`,
                    },
                    {
                      value: '18',
                      description: `This is useful in proxy cases when connection between lift proxy and lift is broken`,
                    },
                    {
                      value: '19',
                      description: `The lift has stopped due to a break in the safety chain`,
                    },
                    {
                      value: '20',
                      description: `The lift is in earthquake mode`,
                    },
                    {
                      value: '21',
                      description: `The lift drives directly to the specified floor and stays there with the doors closed`,
                    },
                    {
                      value: '22',
                      description: `The lift is in car hospital service. Drives only from car calls`,
                    },
                    {
                      value: '23',
                      description: `The lift is in reserve dispatch mode. Special mode when all risers are in failure mode and lift collect passengers according to predefined pattern`,
                    },
                    {
                      value: '26',
                      description: `The lift is reserved to transport automated guided vehicle`,
                    },
                    {
                      value: '27',
                      description: `For safety reasons the lift reduces speed when strong winds are detected`,
                    },
                    {
                      value: '28',
                      description: `For safety reasons the lift stops running when storm winds are detected`,
                    },
                    {
                      value: '29',
                      description: `The lift speed is reduced`,
                    },
                    {
                      value: '30',
                      description: `The lift operates in evacuation mode`,
                    },
                    {
                      value: '31',
                      description: `The lift is switched to cleaning mode, but it is still driving the existing passengers before going some CAM mode`,
                    },
                    {
                      value: '32',
                      description: `The lift is reserved for cleaning and it is not available for normal passengers`,
                    },
                    {
                      value: '33',
                      description: `The lift is switched to some maintenance mode, but it is still driving the existing passengers before going some SRV or OSS mode`,
                    },
                    {
                      value: '34',
                      description: `The lift operates in invasion mode`,
                    },
                    {
                      value: '35',
                      description: `The lift is doing setup drive`,
                    },
                    {
                      value: '36',
                      description: `Lift is in substance release mode`,
                    },
                    {
                      value: '37',
                      description: `Lift is in fire detection mode`,
                    },
                    {
                      value: '38',
                      description: `Lift is in fire detection mode. Fire detected in machine room`,
                    },
                    {
                      value: '39',
                      description: `Lift is in penthouse visitor service mode`,
                    },
                    {
                      value: '40',
                      description: `Priority Good Service. The lift is reserved for moving goods/baggage/products/non-human objects`,
                    },
                    {
                      value: '41',
                      description: `Recover. Group sets lift to this mode if group needs to recover the lift status and can't temporarily command the lift`,
                    },
                    {
                      value: '42',
                      description: `Reserved`,
                    },
                    {
                      value: '43',
                      description: `Car to lobby mode. Lift is requested to go into a specific lobby and to wait for additional actions`,
                    },
                    {
                      value: '44',
                      description: `The lift is in PET mode i.e. transferring a pet which has additional signalization`,
                    },
                    {
                      value: '45',
                      description: `Empty car - lift reserved to serve a call where lift car needs to be empty`,
                    },
                    {
                      value: '46',
                      description: `Lift is reserved for medical emergency service`,
                    },
                    {
                      value: '47',
                      description: `Lift is reserved for retail mode`,
                    },
                    {
                      value: '48',
                      description: `Lift is serving low priority VIP call`,
                    },
                    {
                      value: '49',
                      description: `Lift is serving high priority VIP call`,
                    },
                    {
                      value: '50',
                      description: `Lift is reserved for VIP calls only`,
                    },
                    {
                      value: '51',
                      description: `Lift is in Pandemic mode`,
                    },
                    {
                      value: '52',
                      description: `Lift set to Transformer mode`,
                    },
                    {
                      value: '53',
                      description: `Reserved- not used in this protocol (LCE uses this with C-process ATS)`,
                    },
                    {
                      value: '54',
                      description: `Reserved- not used in this protocol (LCE uses this with C-process PRC)`,
                    },
                    {
                      value: '55',
                      description: `Pit inspection failure`,
                    },
                    {
                      value: '56',
                      description: `Luggage handling feature`,
                    },
                    {
                      value: '57',
                      description: `Technician roof access – Lift is set to roof access mode for technician`,
                    },
                    {
                      value: '58',
                      description: `Technician pit access – Lift is set to pit access mode for technician`,
                    },
                    // {
                    //   value: '99',
                    //   description: `The failure of primary power supply of equipment and equipment is now operating with is backup power supply`,
                    // },
                  ]}
                />
                <PayloadProperty
                  name="in_bank"
                  type="boolean"
                  description={`Lift is operating as part of the elevator group.`}
                  example={`false`}
                />

                <PayloadProperty
                  name="nominal_speed"
                  type="decimal"
                  description={`Lift drive nominal speed`}
                  example={`6.0`}
                />

                <PayloadProperty name="decks" type="array of deck object" required={true} />
                <Row style={{ marginLeft: '60px', width: '100%' }}>
                  <Col span="22">
                    <PayloadProperty
                      name="area"
                      type="number"
                      required={true}
                      description={`Area id of the deck`}
                      example={`1001010`}
                    />

                    <PayloadProperty
                      name="alarm"
                      type="boolean"
                      description={`Alarm pressed status in lift`}
                      example={`false`}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </LeftCol>
      <RightCol span="12">
        <Paragraph>Event for lift status example</Paragraph>

        <CodeBlock language="json">
          {`{
  "subtopic": "lift_1/status",
  "requestId": "760da6e6-a232-448e-a8a0-a4d6e5173fa1",
  "callType": "monitor",
  "buildingId": "building:9990000951",
  "groupId": "1",
  "data" : {
    "time": "2020-01-28T08:17:09.163Z", 
    "recreated": true,
    "fault_active": false,  
    "lift_mode": 12,
    "in_bank": true,
    "nominal_speed": 6.0,
    "decks": [
      {
        "area": 1001010,
        "alarm": false
      }
    ]
  }  
}`}
        </CodeBlock>
      </RightCol>

      <LeftCol span="12">
        <Row id="next-stop-eta">
          <Col span={5}>
            <StyledEventName>next-stop-eta</StyledEventName>
          </Col>
          <Col span={19}>
            <P size="small">
              {' '}
              Occurs whenever (1) lift starts to move towards a next stop and when (2) next stop gets changed during the
              travel. Main purpose for this event is to allow UI applications to animate current lift position as smooth
              animation between two floors with perfect timing, rather than nudging the lift car one whole floor at a
              time, requiring separate state-events for each update, and suffering from delivery lag. Essentially, for
              UI purposes this event should be much better than Lift deck position.
            </P>
          </Col>
        </Row>
        <Row>
          <Col span="24">
            <PayloadProperty
              name="subTopic"
              type="string"
              required={true}
              description={`Subscribed subTopic`}
              format={`lift_LIFT_ID/next_stop_eta`}
              example={`lift_1/next_stop_eta`}
            />
            <PayloadProperty
              name="requestId"
              type="number"
              required={true}
              description={`Unique request ID to pair response to correct request`}
              example={`12345`}
            />
            <PayloadProperty
              name="callType"
              type="string"
              required={true}
              description={`Site monitoring call type`}
              stringEnums={['monitor']}
            />
            <PayloadProperty
              name="buildingId"
              type="string"
              required={true}
              description={`Unique identifier for the building`}
              format={`building:BUILDING_ID`}
              example={`building:9990000951`}
            />
            <PayloadProperty
              name="groupId"
              type="string"
              required={true}
              description={`Unique identifier for the group`}
              example={`"1"`}
              comment={groupIdMessage}
            />
            <PayloadProperty name="data" type="object" required={true} />
            <Row style={{ marginLeft: '60px', width: '90%' }}>
              <Col span="22">
                <PayloadProperty
                  name="time"
                  type="string"
                  required={true}
                  description={`ISO formatted timestamp of the message`}
                  format={`yyyy-MM-dd'T'HH:mm:ss.SSSZ`}
                  example={`2022-03-28T08:17:09.163Z`}
                />
                <PayloadProperty
                  name="recreated"
                  type="boolean"
                  description={`Event is recreated due to user subscription or resubscription to status event`}
                  example={`false`}
                />
                <PayloadProperty
                  name="eta"
                  type="string"
                  required={true}
                  description={`Estimated time of arrival. ISO formatted timestamp`}
                  format={`yyyy-MM-dd'T'HH:mm:ss.SSSZ`}
                  example={`2022-03-28T08:17:10.163Z`}
                />
                <PayloadProperty
                  name="last_start_time"
                  type="string"
                  required={true}
                  description={`Last start time. ISO formatted timestamp`}
                  format={`yyyy-MM-dd'T'HH:mm:ss.SSSZ`}
                  example={`2022-03-28T08:17:10.012Z`}
                />
                <PayloadProperty name="decks" type="array of deck object" required={true} />
                <Row style={{ marginLeft: '60px', width: '100%' }}>
                  <Col span="22">
                    <PayloadProperty
                      name="area"
                      type="number"
                      required={true}
                      description={`Area id of the deck`}
                      example={`1001010`}
                    />

                    <PayloadProperty
                      name="next_stop"
                      type="number"
                      required={true}
                      description={`Group floor Id of next stop`}
                      example={`6`}
                    />
                    <PayloadProperty
                      name="current_position"
                      type="number"
                      required={true}
                      description={`Group floor Id of current position`}
                      example={`28`}
                    />
                    <PayloadProperty
                      name="last_start_position"
                      type="number"
                      description={`Last start position`}
                      example={`31`}
                    />
                    <PayloadProperty
                      name="load_percentage"
                      type="number"
                      description={`Percentage of load. 100 if fully occupied`}
                      example={`50`}
                    />
                    <PayloadProperty
                      name="load_status"
                      type="string"
                      description={`This informs of the current acquired load volume of the equipment`}
                      example={`NORMAL_LOAD`}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </LeftCol>
      <RightCol span="12">
        <Paragraph>Event for next stop eta example</Paragraph>

        <CodeBlock language="json">
          {`{
    "data":
    {
      "time": "2022-09-21T04:52:21.262Z",
      "recreated": true,
      "eta": "2022-09-21T04:52:34.231Z",
      "decks": [
      {
        "area": 1001020,
        "current_position": 13,
        "next_stop": 1,
        "load_percentage": 0,
        "load_status": "NORMAL_LOAD"
      },
      {
        "area": 1001021,
        "current_position": 14,
        "next_stop": 2,
        "load_percentage": 0,
        "load_status": "NORMAL_LOAD"
      }
     ]
    },
    "subtopic": "lift_2/next_stop_eta",
    "requestId": "12345",
    "callType": "monitor",
    "buildingId": "building:1000000832",
    "groupId": "1"
  }
    `}
        </CodeBlock>
      </RightCol>

      <LeftCol span="12">
        <Row id="lift-deck-position">
          <Col span={5}>
            <StyledEventName>lift-deck-position</StyledEventName>
          </Col>
          <Col span={19}>
            <P size="small">
              Occurs whenever if any of the deck states direction, moving state, area, current floor, advanced etc
            </P>
          </Col>
        </Row>
        <Row>
          <Col span="24">
            <PayloadProperty
              name="subTopic"
              type="string"
              required={true}
              description={`Subscribed subTopic`}
              format={`lift_LIFT_ID/position`}
              example={`lift_1/position`}
            />
            <PayloadProperty
              name="requestId"
              type="string"
              required={true}
              description={`Unique request ID to pair response to correct request`}
              example={`01841d1c-f4ba-4f9c-a348-6f679bfae86e`}
            />
            <PayloadProperty
              name="callType"
              type="string"
              required={true}
              description={`Site monitoring call type`}
              stringEnums={['monitor']}
            />
            <PayloadProperty
              name="buildingId"
              type="string"
              required={true}
              description={`Unique identifier for the building`}
              format={`building:BUILDING_ID`}
              example={`building:9990000951`}
            />
            <PayloadProperty
              name="groupId"
              type="string"
              required={true}
              description={`Unique identifier for the group`}
              example={`"1"`}
              comment={groupIdMessage}
            />
            <PayloadProperty name="data" type="object" required={true} />
            <Row style={{ marginLeft: '60px', width: '90%' }}>
              <Col span="22">
                <PayloadProperty
                  name="time"
                  type="string"
                  required={true}
                  description={`ISO formatted timestamp of the message`}
                  format={`yyyy-MM-dd'T'HH:mm:ss.SSSZ`}
                  example={`2022-03-28T08:17:09.163Z`}
                />
                <PayloadProperty
                  name="dir"
                  type="string"
                  required={true}
                  stringEnums={['UP', 'DOWN']}
                  description={`Moving direction of lift deck`}
                  example={`UP`}
                />
                <PayloadProperty
                  name="coll"
                  type="string"
                  required={true}
                  stringEnums={['UP', 'DOWN']}
                  description={`Lift collective direction`}
                  example={`DOWN`}
                />
                <PayloadProperty
                  name="moving_state"
                  type="string"
                  required={true}
                  stringEnums={[`STOPPED`, `STANDING`, `STARTING`, `MOVING`, `DECELERATING`]}
                  description={`Moving state of the lift deck`}
                  example={`STANDING`}
                />
                <PayloadProperty
                  name="drive_mode"
                  type="string"
                  description={`This is a mode that indicates the status of equipment drive and if available for serving calls.`}
                  example={`NORMAL`}
                />
                <PayloadProperty
                  name="serving_mode"
                  type="string"
                  description={`This is a mode that indicates the readiness of the equipment to accept calls or handling of existing calls.`}
                  example={`BUSY`}
                />
                <PayloadProperty
                  name="area"
                  type="number"
                  required={true}
                  description={`Area id of the lift deck`}
                  example={`1001010`}
                />

                <PayloadProperty
                  name="cur"
                  type="number"
                  required={true}
                  description={`Current floor id`}
                  example={`12`}
                />

                <PayloadProperty
                  name="adv"
                  type="number"
                  required={true}
                  description={`Advanced floor id`}
                  example={`14`}
                />

                <PayloadProperty
                  name="door"
                  type="boolean"
                  description={`Indicates if the lift deck is at landing door zone`}
                  example={`false`}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </LeftCol>
      <RightCol span="12">
        <Paragraph>Event for lift position example</Paragraph>

        <CodeBlock language="json">
          {`{
  "subtopic": "lift_1/position",
  "requestId": "760da6e6-a232-448e-a8a0-a4d6e5173fa1",
  "callType": "monitor",
  "buildingId": "building:9990000951",
  "groupId": "1",
  "data" : {
    "time": "2022-03-28T08:17:09.163Z", 
    "dir": "UP",
    "coll": "DOWN",
    "moving_state": "DECELERATING", 
    "drive_mode": "NORMAL",
    "serving_mode": "BUSY",
    "area": 1001010,
    "cur": 12,
    "adv": 14,
    "door": false
  }
}`}
        </CodeBlock>
      </RightCol>
      <LeftCol span="12">
        <Row id="lift-stopping">
          <Col span={5}>
            <StyledEventName>lift-stopping</StyledEventName>
          </Col>
          <Col span={19}>
            <P size="small">
              Published when lift starts slowing down to a stop where at least one of the doors opens. Only the doors
              that will open are published. Only the decks that have some opening doors will be published.
            </P>
          </Col>
        </Row>
        <Row>
          <Col span="24">
            <PayloadProperty
              name="subTopic"
              type="string"
              required={true}
              description={`Subscribed subTopic`}
              format={`lift_LIFT_ID/stopping`}
              example={`lift_1/stopping`}
            />
            <PayloadProperty
              name="requestId"
              type="string"
              required={true}
              description={`Unique request ID to pair response to correct request`}
              example={`01841d1c-f4ba-4f9c-a348-6f679bfae86e`}
            />
            <PayloadProperty
              name="callType"
              type="string"
              required={true}
              description={`Site monitoring call type`}
              stringEnums={['monitor']}
            />
            <PayloadProperty
              name="buildingId"
              type="string"
              required={true}
              description={`Unique identifier for the building`}
              format={`building:BUILDING_ID`}
              example={`building:9990000951`}
            />
            <PayloadProperty
              name="groupId"
              type="string"
              required={true}
              description={`Unique identifier for the group`}
              example={`"1"`}
              comment={groupIdMessage}
            />
            <PayloadProperty name="data" type="object" required={true} />
            <Row style={{ marginLeft: '60px', width: '90%' }}>
              <Col span="22">
                <PayloadProperty
                  name="time"
                  type="string"
                  required={true}
                  description={`ISO formatted timestamp of the message`}
                  example={`2022-03-28T08:17:09.163Z`}
                  format={`yyyy-MM-dd'T'HH:mm:ss.SSSZ`}
                />
                <PayloadProperty name="decks" type="array of deck object" required={true} />
                <Row style={{ marginLeft: '60px', width: '90%' }}>
                  <Col span="22">
                    <PayloadProperty
                      name="area"
                      type="number"
                      required={true}
                      description={`Area id of the deck`}
                      example={`1001010`}
                    />
                    <PayloadProperty name="doors" type="array of door object" required={true} />
                    <Row style={{ marginLeft: '60px', width: '90%' }}>
                      <Col span="22">
                        <PayloadProperty
                          name="landing"
                          type="number"
                          required={true}
                          description={`Area Id of the landing door that opens to`}
                          example={`5010`}
                        />
                        <PayloadProperty
                          name="lift_side"
                          type="number"
                          required={true}
                          description={`Lift side of the door`}
                          example={`1`}
                        />
                        <PayloadProperty
                          name="inbound"
                          type="array of numbers"
                          required={true}
                          description={`Session IDs of calls for passengers coming into car on the lift stop`}
                          example={`[24386, 24387]`}
                        />
                        <PayloadProperty
                          name="outbound"
                          type="array of numbers"
                          required={true}
                          description={`Session IDs of calls for passengers exiting from the car on the lift stop`}
                          example={`[86867]`}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </LeftCol>
      <RightCol span="12">
        <Paragraph>Event for lift stopping example</Paragraph>

        <CodeBlock language="json">
          {`{
  "subtopic": "lift_1/stopping",
  "requestId": "760da6e6-a232-448e-a8a0-a4d6e5173fa1",
  "callType": "monitor",
  "buildingId": "building:9990000951",
  "groupId": "1",
  "data" : {
    "time": "2020-01-28T08:17:09.163Z",
    "decks": [
      {
        "area": 1001010,
        "doors": [                         
          {
            "landing": 5010,
            "lift_side": 1,
            "inbound": [ 24386, 24387 ],
            "outbound": [ 86867 ]
          }
        ]
      }
    ]
  }
}`}
        </CodeBlock>
      </RightCol>

      <LeftCol span="12">
        <Row id="door-state">
          <Col span={5}>
            <StyledEventName>door-state</StyledEventName>
          </Col>
          <Col span={19}>
            <P size="small">
              Published whenever a door state changes. On an lift with multiple decks and multiple doors, separate
              events are triggered for each
            </P>
          </Col>
        </Row>
        <Row>
          <Col span="24">
            <PayloadProperty
              name="subTopic"
              type="string"
              required={true}
              description={`Subscribed subTopic`}
              format={`lift_LIFT_ID/doors`}
              example={`lift_1/doors`}
            />
            <PayloadProperty
              name="requestId"
              type="string"
              required={true}
              description={`Unique request ID to pair response to correct request`}
              example={`01841d1c-f4ba-4f9c-a348-6f679bfae86e`}
            />
            <PayloadProperty
              name="callType"
              type="string"
              required={true}
              description={`Site monitoring call type`}
              stringEnums={['monitor']}
            />
            <PayloadProperty
              name="buildingId"
              type="string"
              required={true}
              description={`Unique identifier for the building`}
              format={`building:BUILDING_ID`}
              example={`building:9990000951`}
            />
            <PayloadProperty
              name="groupId"
              type="string"
              required={true}
              description={`Unique identifier for the group`}
              example={`"1"`}
              comment={groupIdMessage}
            />
            <PayloadProperty name="data" type="object" required={true} />
            <Row style={{ marginLeft: '60px', width: '90%' }}>
              <Col span="22">
                <PayloadProperty
                  name="time"
                  type="string"
                  required={true}
                  description={`ISO formatted timestamp of the message`}
                  format={`yyyy-MM-dd'T'HH:mm:ss.SSSZ`}
                  example={`2022-03-28T08:17:09.163Z`}
                />
                <PayloadProperty
                  name="area"
                  type="number"
                  required={true}
                  description={`Area id of the deck`}
                  example={`1001010`}
                />
                <PayloadProperty
                  name="landing"
                  type="number"
                  description={`Area Id of the landing door leads to if any`}
                  example={`5010`}
                />
                <PayloadProperty
                  name="lift_side"
                  type="number"
                  required={true}
                  description={`Lift side of the door`}
                  example={`1`}
                />
                <PayloadProperty
                  name="state"
                  type="string"
                  required={true}
                  stringEnums={[`STOPPED`, `OPENING`, `OPENED`, `CLOSING`, `CLOSED`, `NUDGING`, `ERROR`]}
                  description={`Lift door state`}
                  example={`CLOSED`}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </LeftCol>
      <RightCol span="12">
        <Paragraph>Event for door state example</Paragraph>

        <CodeBlock language="json">
          {`{
  "subtopic": "lift_1/doors",
  "requestId": "760da6e6-a232-448e-a8a0-a4d6e5173fa1",
  "callType": "monitor",
  "buildingId": "building:9990000951",
  "groupId": "1",
  "data" : {
    "time": "2022-03-28T08:17:09.163Z",
    "area": 1001010,
    "landing": 5010,
    "lift_side": 1,
    "state": "CLOSED"
  }
}`}
        </CodeBlock>
      </RightCol>
      <LeftCol span="12">
        <Row id="call-state">
          <Col span={5}>
            <StyledEventName>call-state</StyledEventName>
          </Col>
          <Col span={19}>
            <P size="small">Occurs when the call state is changed for Destination, Landing and Car calls.</P>
            <br />
          </Col>
        </Row>
        <Row>
          <Col span="24">
            <PayloadProperty
              name="subTopic"
              type="string"
              required={true}
              description={`Subscribed subTopic`}
              format={`call_state/sesson_id/fixed`}
              example={`call_state/123/fixed`}
            />
            <PayloadProperty
              name="requestId"
              type="string"
              required={true}
              description={`Unique request ID to pair response to correct request`}
              example={`01841d1c-f4ba-4f9c-a348-6f679bfae86e`}
            />
            <PayloadProperty
              name="callType"
              type="string"
              required={true}
              description={`Site monitoring call type`}
              stringEnums={['monitor']}
            />
            <PayloadProperty
              name="buildingId"
              type="string"
              required={true}
              description={`Unique identifier for the building`}
              format={`building:BUILDING_ID`}
              example={`building:9990000951`}
            />
            <PayloadProperty
              name="groupId"
              type="string"
              required={true}
              description={`Unique identifier for the group`}
              example={`"1"`}
              comment={groupIdMessage}
            />
            <PayloadProperty name="data" type="object" required={true} />
            <Row style={{ marginLeft: '60px', width: '90%' }}>
              <Col span="22">
                <PayloadProperty
                  name="time"
                  type="string"
                  required={true}
                  description={`ISO formatted timestamp of the message`}
                  example={`2022-03-28T08:17:09.163Z`}
                />
                <PayloadProperty name="decks" type="array of deck object" required={true} />
                <Row style={{ marginLeft: '60px', width: '90%' }}>
                  <Col span="22">
                    <PayloadProperty
                      name="area"
                      type="number"
                      required={true}
                      description={`Area id of the deck`}
                      example={`1001010`}
                    />

                    <PayloadProperty
                      name="user_id"
                      type="string"
                      description={`User Id in hex string provided by Access Control system`}
                      example={`ab1376ef`}
                    />

                    <PayloadProperty
                      name="allocated_lift_deck"
                      type="Number Array"
                      description={`Lift deck area id that has been allocated`}
                      example={`[1001010]`}
                    />

                    <PayloadProperty
                      name="eta"
                      type="string"
                      required={true}
                      description={`Estimated time of Arrival of the lift arriving at the source floor of this call. This ETA is not accurate, it may increase by a lot, 
                      causing this event to be published again. As such, displaying any ETA as a number is very unreliable, but the data can be used in progress bars etc. 
                      This field is only included for states up to fixed. At that stage, the ETA is also final and will no longer change.`}
                      format={`yyyy-MM-dd'T'HH:mm:ss.SSSZ`}
                      example={`2022-03-28T08:17:10.163Z`}
                    />

                    <PayloadProperty
                      name="cancel_reason"
                      type="string"
                      description={`Reason for cancellation if any`}
                      example={`NO_LIFT_AVAILABLE`}
                      stringEnumDescriptions={[
                        {
                          value: 'DROPPED_BY_LIFT',
                          description: `Lift has dropped the call without serving it. Possible reasons include connection issues, elevator breakdown, PRL H / HEL calls and so on.`,
                        },
                        {
                          value: 'NO_LIFT_AVAILABLE',
                          description: `No lifts are currently available to serve this specific call. They may be for example offline, out of service or serving PRL E calls.`,
                        },
                        {
                          value: 'FLOOR_IS_LOCKED',
                          description: `Either the source or the destination floor is locked with lift locks.`,
                        },
                        {
                          value: 'ACCESS_DENIED',
                          description: ` The call is prevented by access control locks.`,
                        },
                        {
                          value: 'NO_CAPACITY ',
                          description: ` The call can not be allocated to any lift due to space or signalization restraints, e.g. lift car does not have enough space, lift is
                           starting to wrong direction next time it arrives to the floor, lift is serving with invalid deck (for the call) next time it arrives to the floor. 
                           Only valid for immediately allocated calls.`,
                        },
                        {
                          value: 'SLAVE_MODE',
                          description: `Call has been given to the backup group. Should not happen normally, but theoretically possible if call is given while master/slave 
                          change is in progress.`,
                        },
                        {
                          value: 'EVACUATION_MODE ',
                          description: `Group is evacuating and does not accept the call.`,
                        },
                        {
                          value: 'CALL_REPLACED ',
                          description: `Call replaced with another. Could be due to e.g. changing the call destination due to evacuation or replacing the old call with a new one 
                          from the same passenger due to the CCH feature.`,
                        },
                        {
                          value: 'DELETE_CALL ',
                          description: ` Call is deleted by the original entity that gave it.`,
                        },
                        {
                          value: 'SYSTEM_RESTART ',
                          description: `Call is cancelled due to system restart.`,
                        },
                      ]}
                    />
                    <PayloadProperty
                      name="subgroup_id"
                      type="number"
                      description={`Default 1, if state is being assigned`}
                      example={`1`}
                    />
                    <PayloadProperty
                      name="modified_destination"
                      type="string"
                      description={`If for some reason the destination area is changed, new destination is given to user. This happens with all transfer calls`}
                      example={`15010`}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </LeftCol>
      <RightCol span="12">
        <Paragraph>Event for call state example</Paragraph>

        <CodeBlock language="json">
          {`{
  "subtopic": "call_state/123/fixed",
  "requestId": "760da6e6-a232-448e-a8a0-a4d6e5173fa1",
  "callType": "monitor",
  "buildingId": "building:9990000951",
  "groupId": "1",
  "data" : {
    "time": "2022-03-28T08:17:09.163Z",
    "user_id": "ab1376ef",             
    "allocated_lift_deck": [ 1001010 ],
    "eta": "2020-01-28T08:17:22.424Z",
    "cancel_reason": "NO_LIFT_AVAILABLE"
    "subgroup_id": 1,                  
    "modified_destination": 15010     
  }
}`}
        </CodeBlock>
      </RightCol>
    </SectionRow>
  )
}
