import { Col, Row } from 'antd'
import React, { FC } from 'react'
import { AsyncApiProps } from '..'
import { H3, H4, P, UL } from '../../../../shared/components/Typography'
import { PayloadProperty } from '../../components/PayloadProperty'
import { CodeBlock, LeftCol, Paragraph, RightCol, SectionRow, StyledEventName } from '../../Styles'
import styled from 'styled-components'
import { NavHashLink } from 'react-router-hash-link'
export interface LiftCallProps {
  asyncApiProps: AsyncApiProps
}

export const Table = styled.div`
  th,
  td {
    border-bottom: 1px solid #474242;
    padding: 10px;
    text-align: left;
    background: #1c2226;
  }
`

const liftCallString = 'lift-call-api-v2'
const groupIdMessage = 'groupId can be integer. Refer MultiGroup in Glossary'

export const LiftCall: FC<LiftCallProps> = ({ asyncApiProps }) => {
  return (
    <SectionRow>
      <LeftCol span="12">
        <H3>WebSocket Requests</H3>
        <Row>
          <P size="small">
            Requests will always receive an acknowledgement with matching requestId. Any response status of 2xx should
            be interpreted as success.
          </P>
        </Row>
      </LeftCol>
      <RightCol span="12"></RightCol>

      <LeftCol span="12">
        <H4 id="make-an-elevator-call">Make an elevator call</H4>

        <Row>
          <Col span={6}>
            <StyledEventName>lift-call</StyledEventName>
          </Col>
          <Col span={18}>
            <P size="small">
              Use <strong>lift-call</strong> to make a destination call or landing call. A destination call sets both
              the sourceId and the destinationId to move the elevator between specific areas. A landing call sets both
              the sourceId and the direction to order the elevator to a specific floor. Areas relate to a building
              topology information found from the common commands section using config action
              <br />
              WebSocket API can handle multiple calls in the same socket. Call events are tied to the socket that
              creates them. By default, the connection is closed after the client is not expecting any more state events
              from the call. If the client does not specify any monitoring events, the connection will be closed after
              receiving the response. <br />
              <br />
              Response status codes should be interpreted as in the HTTP specification:
            </P>
            <UL size="small">
              <li>201 - Call has been registered to the system</li>
              <li>400 - Call payload validation error</li>
              <li>401 - Not authenticated or token expired</li>
              <li>403 - Client is forbidden from performing the request. Please check the provided scopes.</li>
              <li>404 - Invalid building id</li>
              <li>
                409 - Conflicting request. Eg. <strong>requestId</strong> not unique within the connection.
              </li>
              <li>500 - Internal error</li>
            </UL>
          </Col>
        </Row>
        <Row>
          <Col span="22">
            <PayloadProperty
              name="type"
              type="string"
              required={true}
              description={`Creating a lift-call gives the possibility to generate either a destination call or a landing call`}
              stringEnums={[liftCallString]}
            />

            <PayloadProperty
              name="callType"
              type="string"
              required={true}
              description={`For lift calls, call type action is needed`}
              stringEnums={['action']}
            />

            <PayloadProperty
              name="buildingId"
              type="string"
              required={true}
              description={`Unique identifier for the building`}
              format={`building:BUILDING_ID`}
              example={`building:9990000951`}
            />

            <PayloadProperty
              name="groupId"
              type="string"
              required={true}
              description={`Unique identifier for the group`}
              example={`"1"`}
              comment={groupIdMessage}
            />
            {/* <PayloadProperty
              name=""
              type=""
              required={true}
              description={``}
              stringEnums={[]}
              stringEnumDescriptions={[
                {
                  value: '',
                  description: ``,
                },
                {
                  value: '',
                  description: ``,
                },
              ]}
            /> */}

            <PayloadProperty name="payload" type="object" required={true} />
            <Row style={{ marginLeft: '60px' }}>
              <Col span="22">
                <PayloadProperty
                  name="request_id"
                  type="number"
                  required={true}
                  description={`Unique request ID to pair response to correct request`}
                  example={`1234`}
                  comment={`request ID is of Type: Integer and  Max 10 digits. Max Value: 2147483647`}
                />

                <PayloadProperty
                  name="area"
                  type="number"
                  required={true}
                  description={`The area where the action is made at. Also known as 'source floor'.`}
                  example={`1000`}
                />

                <PayloadProperty
                  name="time"
                  type="string"
                  required={true}
                  description={`ISO 8601 time string`}
                  format={`yyyy-MM-dd'T'HH:mm:ss.SSSZ`}
                  example={`2022-03-28T08:17:09.163Z`}
                />

                <PayloadProperty
                  name="terminal"
                  type="number"
                  required={true}
                  description={`Terminal ID where the action is made at. Min = 1, Max = 99999.`}
                  example={`1`}
                />

                <PayloadProperty
                  name="media_type"
                  type="string"
                  description={`Needed for access control based calls`}
                  stringEnums={['RFID', 'BLUETOOTH', 'FACE', 'QR']}
                  example={`RFID or BLUETOOTH or FACE or QR`}
                />

                <PayloadProperty
                  name="media_cc"
                  type="string"
                  description={`Company code portion of the media id`}
                  example={`abc_0123`}
                />

                <PayloadProperty
                  name="media_id"
                  type="string"
                  description={`Access control unlock for the media id for the selected action`}
                  example={`0123345abcd`}
                />
                <PayloadProperty name="call" type="object" required={true} />
                <Row style={{ marginLeft: '60px', width: '100%' }}>
                  <Col span="22">
                    <PayloadProperty
                      name="action"
                      type="number"
                      description={`Action Id for landing calls, destination calls and car calls`}
                      required={true}
                      example={`22`}
                    />

                    <PayloadProperty
                      name="destination"
                      type="number"
                      description={`Destination area if given action supports destination calls or car calls`}
                      example={`7000`}
                    />

                    <PayloadProperty
                      name="delay"
                      type="number"
                      description={`Default: 0. Min: 0, Max: 30. Delay in seconds, this is added to any walking time determined based on the terminal.`}
                      example={`10`}
                    />

                    <PayloadProperty
                      name="group_size"
                      type="number"
                      description={`Default: 1. Min: 1, Max: 100. To make the call request as a group call, provide group size > 1.
                      If requested group is larger than one lift can fit, system will still only allocate one lift.`}
                      example={`1`}
                    />

                    <PayloadProperty
                      name="allowed_lifts"
                      type="Number Array"
                      description={`Lift Decks IDS`}
                      example={`[1001010, 1001110]`}
                    />
                    {/* After advanced testing, the documentation part will be uncommented. */}
                    <PayloadProperty
                      name="activate_call_states"
                      type="String Array"
                      description={`List of call states to subscribe. By adding this parameter we can receive monitoring response of call states.`}
                      example={`["being_fixed"]`}
                      attach={
                        <>
                          <p>
                            Destination calls and landing calls generate a session ID that can be monitored using
                            activate_call_states. However, car calls do not produce session id, they cannot be monitored
                            with activate_call_states. :-
                            <NavHashLink smooth to="#Available-call-states">
                              Available call states
                            </NavHashLink>
                          </p>
                          <p>
                            Response of Call State : -
                            <NavHashLink smooth to="#call-state">
                              Call State Response
                            </NavHashLink>
                          </p>
                        </>
                      }
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </LeftCol>
      <RightCol span="12">
        <Paragraph>Request example for destination call</Paragraph>

        <CodeBlock language="json">
          {`{
  "type": "lift-call-api-v2",
  "buildingId": "building:9990000951",
  "callType": "action",
  "groupId": "1",
  "payload": {
     "request_id": 252390420,
     "area": 3000, //source floor area id
     "time": "2022-03-10T07:17:33.298515Z",
     "terminal": 1,
     "call": {
        "action": 2,
        "destination": 5000, //destination floor area id
        "activate_call_states": ["being_fixed"]
      }
    }
  }`}
        </CodeBlock>
        <Paragraph>Request example for landing call</Paragraph>
        <CodeBlock language="json">
          {`{
  "type": "lift-call-api-v2",
  "buildingId": "building:9990000951",
  "callType": "action",
  "groupId": "1",
  "payload": {
     "request_id": 252390420,
     "area": 3000, //source floor area id
     "time": "2022-03-10T07:17:33.298515Z",
     "terminal": 1,
     "call": {
        "action": 2002,
        "activate_call_states": ["being_fixed"]
      }
    }
}`}
        </CodeBlock>
        <Paragraph>Request example for car call</Paragraph>
        <CodeBlock language="json">
          {`{
  "type": "lift-call-api-v2",
  "buildingId": "building:9990000951",
  "callType": "action",
  "groupId": "1",
  "payload": {
     "request_id": 252390420,
     "area": 1001010, //lift deck area id
     "time": "2022-03-10T07:17:33.298515Z",
     "terminal": 1,
     "call": {
        "action": 5000,
        "destination": 3000  //destination floor area id
      }
    }
 }`}
        </CodeBlock>
        <Paragraph>Request example for access control based calls</Paragraph>
        <CodeBlock language="json">
          {`{
  "type": "lift-call-api-v2",
  "buildingId": "building:9990000951",
  "callType": "action",
  "groupId": "1",
  "payload": {
     "request_id": 252390420,
     "media_type": "RFID",
     "media_id": "00008573f4",
     "media_cc": "123",
     "area": 1000, //source floor area id
     "time": "2022-03-10T07:17:33.298515Z",
     "terminal": 1,
     "call": {
        "action": 2,
        "destination": 3000, //destination floor area id
        "activate_call_states": ["being_fixed"]
      }
    }
 }`}
        </CodeBlock>

        <Paragraph>Acknowledgement response</Paragraph>

        <CodeBlock language="json">
          {`{
  "connectionId": "PzIhJfgdjoECG1A=",
  "requestId": 896311383,
  "statusCode": 201,
  "data": {
    "time": "2022-04-08T10:27:08.272Z"
 }
}`}
        </CodeBlock>

        <Paragraph>Request payload validation error</Paragraph>

        <CodeBlock language="json">
          {`{
  "type": "error",
  "connectionId": "ZLOG3c0SDoECFnQ=",
  "requestId": 929034315,
  "statusCode": 400,
  "data": {
    "error": "'callType' is required"
  }
}`}
        </CodeBlock>
        <Paragraph>Event payload when the Destination, Landing and Access control based calls are successful</Paragraph>

        <CodeBlock language="json">
          {`{
  "data": {
    "request_id": 375581225,
    "success": true,
    "session_id": 123,
    "allocation_mode": "Immediate"  // It is optional field
  },
  "callType": "action",
  "buildingId": "building:9990000951",
  "groupId": "1"
}`}
        </CodeBlock>
        <Paragraph>Event payload when the Car call is successful</Paragraph>

        <CodeBlock language="json">
          {`{
  "data": {
    "request_id": 375581226,
    "success": true
},
  "callType": "action",
  "buildingId": "building:9990000951",
  "groupId": "1"
}`}
        </CodeBlock>
        <Paragraph>Transfer floor call response payload</Paragraph>

        <CodeBlock language="json">
          {`{
"data": {
  "request_id": 375581225,
  "success": true,
  "session_id": 123,
  "modified_destination": 31000,
  "modify_reason": "ManualTransfer"
},
"callType": "action",
"buildingId": "building:9990000951",
"groupId": "1"
}`}
        </CodeBlock>
        <Paragraph>Event payload when the call is unsuccessful</Paragraph>

        <CodeBlock language="json">
          {`{
  "data": {
    "request_id": 375581225,
    "success": false,
    "error": "NO_LIFT_AVAILABLE"
  },
  "callType": "action",
  "buildingId": "building:9990000951",
  "groupId": "1"
}`}
        </CodeBlock>
        <Table>
          <Paragraph>Possible Error Values</Paragraph>
          <table
            className="table table-bordered table-hover table-condensed"
            style={{ display: 'block', overflowX: 'auto', padding: '0.5em' }}
          >
            <thead>
              <tr>
                <th>Call Reject Reasons</th>
                <th>Other Error Responses</th>
                <th>LockTypes</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>FLOOR_DOES_NOT_EXIST</td>
                <td>LIFT_RESPONSE_TIMEOUT</td>
                <td>UNKNOWN (NUMERIC ID)</td>
              </tr>
              <tr>
                <td>INVALID_SIDE</td>
                <td>REJECTED_BY_LIFT</td>
                <td>ACS</td>
              </tr>
              <tr>
                <td>CALLUID_EXISTS</td>
                <td>INVALID_REQUEST</td>
                <td>PF_LOCK</td>
              </tr>
              <tr>
                <td>NO_LIFT_AVAILABLE</td>
                <td></td>
                <td>EVEN_ODD</td>
              </tr>
              <tr>
                <td>INVALID_LIFT_ID</td>
                <td></td>
                <td>EVACUATION</td>
              </tr>
              <tr>
                <td>INVALID_TERMINAL_ID</td>
                <td></td>
                <td>INVASION</td>
              </tr>
              <tr>
                <td>INVALID_DIRECTION</td>
                <td></td>
                <td>CROWDED</td>
              </tr>
              <tr>
                <td>INVALID_CALL_KEY</td>
                <td></td>
                <td>OVERCROWDED</td>
              </tr>
              <tr>
                <td>INVALID_PASSENGER_COUNT</td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>FLOOR_IS_LOCKED</td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>ACCESS_DENIED</td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>SLAVE_MODE</td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>EVACUATION_MODE</td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>INVASION_NO_SERVICE_TO_FLOOR</td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>INVASION_NO_SERVICE_FROM_FLOOR</td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>CALL_REPLACED</td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>CONSECUTIVE_CALL</td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>REJECT_WITH_INDICATION</td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </Table>
      </RightCol>
      {asyncApiProps.callType === 'robot' && (
        <>
          <LeftCol span="12">
            <H4 id="hold-car-door-open">Hold car door open</H4>

            <Row>
              <Col span={6}>
                <StyledEventName>hold_open</StyledEventName>
              </Col>
              <Col span={18}>
                <P size="small">
                  To hold a door open for extended period of time, e.g. by a robot using the API. Recommended way is to
                  extend the time by 5 seconds at a time as many times as necessary. An extension is maintained for each
                  client separately; new request by same client just replaces the old one. This allows a client to
                  update its value to 0 when the client no longer needs the door to stay open. Whether it closes
                  immediately after that depends on other conditions, such as other clients. Using this interface to
                  hold the door open will not exceed the normal door opening limitations: eventually lift will nudge the
                  doors to forcibly close them. Intent is that this interface works like virtually breaking the curtain
                  of light: to keep the door from closing, but not infinitely. The requested time is always measured as
                  an offset from the given timestamp. So, client is expected to maintain its clock in sync with the
                  Group. If the message sent by client is delayed by any reason, for example, if the request arrives 10
                  seconds later requesting the door to be held for 5 seconds, the message would just get ignored as the
                  requested door time has effectively expired 5 seconds ago. This is just to make sure that if message
                  gets delayed potentially by any random amount of time, it doesnt affect the system negatively.
                  <br />
                  When we set both hard time and soft time to 0, the door will close immediately.
                  <br />
                  <br />
                  Response status codes should be interpreted as in the HTTP specification:
                </P>
                <UL size="small">
                  <li>400 - Call payload validation error</li>
                  <li>401 - Not authenticated or token expired</li>
                  <li>403 - Client is forbidden from performing the request. Please check the provided scopes.</li>
                  <li>404 - Invalid building id</li>
                  <li>
                    409 - Conflicting request. Eg. <strong>requestId</strong> not unique within the connection.
                  </li>
                  <li>500 - Internal error</li>
                </UL>
              </Col>
            </Row>
            <Row>
              <Col span="22">
                <PayloadProperty
                  name="type"
                  type="string"
                  required={true}
                  description={`Creating a lift-call gives the possibility to generate either a destination call or a landing call`}
                  stringEnums={[liftCallString]}
                />

                <PayloadProperty
                  name="callType"
                  type="string"
                  required={true}
                  description={`For hold open the door request, call type hold_open is needed`}
                  stringEnums={['hold_open']}
                />

                <PayloadProperty
                  name="buildingId"
                  type="string"
                  required={true}
                  description={`Unique identifier for the building`}
                  example={`building:9990000951`}
                />

                <PayloadProperty
                  name="groupId"
                  type="string"
                  required={true}
                  description={`Unique identifier for the group`}
                  example={`"1"`}
                  comment={groupIdMessage}
                />
                {/* <PayloadProperty
              name=""
              type=""
              required={true}
              description={``}
              stringEnums={[]}
              stringEnumDescriptions={[
                {
                  value: '',
                  description: ``,
                },
                {
                  value: '',
                  description: ``,
                },
              ]}
            /> */}

                <PayloadProperty name="payload" type="object" required={true} />
                <Row style={{ marginLeft: '60px' }}>
                  <Col span="22">
                    <PayloadProperty
                      name="request_id"
                      type="number"
                      description={`Unique request ID to pair response to correct request. Request is accepted without request_id too, but then there will be no response.`}
                      example={`1234`}
                      comment={`request ID is of Type: Integer and  Max 10 digits. Max Value: 2147483647`}
                    />

                    <PayloadProperty
                      name="time"
                      type="string"
                      required={true}
                      description={`ISO 8601 time string`}
                      example={`2022-03-28T08:17:09.163Z`}
                    />

                    <PayloadProperty
                      name="lift_deck"
                      type="number"
                      required={true}
                      description={`Area Id of the lift deck that contains door`}
                      example={`1001010`}
                    />

                    <PayloadProperty
                      name="served_area"
                      type="number"
                      required={true}
                      description={`The area that door is leading to. The request affects the door between the two areas the lift car and the landing`}
                      example={`5010`}
                    />

                    <PayloadProperty
                      name="hard_time"
                      type="number"
                      required={true}
                      description={`Duration for holding the doors open, in seconds. Min: 0, Max: 10.`}
                      example={`5`}
                    />

                    <PayloadProperty
                      name="soft_time"
                      type="number"
                      description={`Default: 0 seconds. Min: 0, Max: 30. The soft_time ends automatically by door sensor if anyone passes through,
                      whereas hard_time keeps doors always open for full duration.`}
                      example={`5`}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </LeftCol>
          <RightCol span="12">
            <Paragraph>Request example for door hold</Paragraph>

            <CodeBlock language="json">
              {`{
  "type": "lift-call-api-v2",
  "buildingId": "building:9990000951",
  "callType": "hold_open",
  "groupId": "1",
  "payload": {
    "request_id": 1352,
    "time": "2020-10-10T07:17:33.298Z",
    "lift_deck": 1001010,
    "served_area": 5000,
    "hard_time": 5,
    "soft_time": 15
  }
}`}
            </CodeBlock>
            <Paragraph>Acknowledgement response</Paragraph>

            <CodeBlock language="json">
              {`{
  "connectionId": "PzIhJfgdjoECG1A=",
  "requestId": "ca1f79b3-ba1c-43cd-b845-364f71b5e6b5",
  "statusCode": 201,
  "data": {
    "time": "2022-04-08T10:27:08.272Z"
 }
}`}
            </CodeBlock>
            <Paragraph>Request payload validation error</Paragraph>

            <CodeBlock language="json">
              {`{
  "type": "error",
  "connectionId": "ZLOG3c0SDoECFnQ=",
  "requestId": 1352,
  "statusCode": 400,
  "data": {
    "error": "'callType' is required"
  }
}`}
            </CodeBlock>
            <Paragraph>Event payload when the call is successful</Paragraph>

            <CodeBlock language="json">
              {`{
  "data": {
    "request_id": 1352,
    "success": true
  },
  "callType": "hold_open",
  "buildingId": "building:9990000951",
  "groupId": "1"
}`}
            </CodeBlock>
            <Paragraph>Event payload when the call is unsuccessful</Paragraph>

            <CodeBlock language="json">
              {`{
  "data": {
    "request_id": 1352,
    "success": false,
    "error": "INVALID_REQUEST:unknown lift deck: area:12345"
  },
  "callType": "hold_open",
  "buildingId": "building:9990000951",
  "groupId": "1"
}`}
            </CodeBlock>
            <Table>
              <Paragraph>Possible Error Values</Paragraph>
              <table
                className="table table-bordered table-hover table-condensed"
                style={{ display: 'block', overflowX: 'auto', padding: '0.5em' }}
              >
                <thead>
                  <tr>
                    <th>Error Responses</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>INVALID_REQUEST:unknown lift deck: area:12345</td>
                  </tr>
                  <tr>
                    <td>INVALID_REQUEST:Invalid json payload</td>
                  </tr>
                  <tr>
                    <td>INVALID_REQUEST:unknown lift deck: area:12345</td>
                  </tr>
                  <tr>
                    <td>INVALID_REQUEST:unknown lobby: area:12345</td>
                  </tr>
                  <tr>
                    <td>INVALID_REQUEST:no valid doorstop found for lift_id at floor, side</td>
                  </tr>
                  <tr>
                    <td>
                      INVALID_REQUEST:unable to convert timestamp: Time stamp is not in ISO8601 format:
                      2022-03-013T20:45:11.748768Z
                    </td>
                  </tr>
                  <tr>
                    <td>INVALID_REQUEST:requested time is in the past: 07.02.2022 18:35:41:879</td>
                  </tr>
                </tbody>
              </table>
            </Table>
          </RightCol>
        </>
      )}
      <LeftCol span="12">
        <H4 id="cancel-an-elevator-call">Cancel an elevator call</H4>

        <Row>
          <Col span={6}>
            <StyledEventName>cancel-call</StyledEventName>
          </Col>
          <Col span={18}>
            <P size="small">
              Use the <strong>cancel-call</strong> request to cancel a previously created elevator call. For the
              request, you need <strong>session_id</strong> of the specific call.
            </P>
            <P size="small">
              Receiving a successful acknowledgment for cancel-call does not always guarantee that the cancellation will
              be acted upon. Successful cancellation results to lift-call-state event sent as canceled for the original
              lift-call request.
            </P>
            <UL size="small">
              <li>202 - Accepted</li>
              <li>400 - Payload validation error</li>
              <li>404 - Invalid cancel request id</li>
              <li>409 - Request was not in cancellable state</li>
              <li>500 - Internal error</li>
            </UL>
          </Col>
          <Row>
            <Col>
              <PayloadProperty
                name="type"
                type="string"
                required={true}
                description={`Creating a lift-call gives the possibility to generate either a destination call or a landing call`}
                stringEnums={[liftCallString]}
              />

              <PayloadProperty
                name="callType"
                type="string"
                required={true}
                description={`To request on-going call deletion, call type delete is needed`}
                stringEnums={['delete']}
              />

              <PayloadProperty
                name="buildingId"
                type="string"
                required={true}
                description={`Unique identifier for the building`}
                example={`building:100000123`}
                format={`building:BUILDING_ID`}
              />

              <PayloadProperty
                name="groupId"
                type="string"
                required={true}
                description={`Unique identifier for the group`}
                example={`"1"`}
                comment={groupIdMessage}
              />

              <PayloadProperty name="payload" type="object" required={true} />
              <Row style={{ marginLeft: '60px' }}>
                <Col span="22">
                  <PayloadProperty
                    name="session_id"
                    type="number"
                    required={true}
                    description={`Session Id from the the original call response. Removes the call from the system if possible`}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Row>
      </LeftCol>
      <RightCol span="12">
        <Paragraph>Request example</Paragraph>

        <CodeBlock language="json">
          {`{
    "type": "lift-call-api-v2",
    "buildingId": "building:9990000951",
    "callType": "delete",
    "groupId": "1",
    "payload": {
      "session_id": 234
    }
}`}
        </CodeBlock>
        <Paragraph>Acknowledgement response</Paragraph>

        <CodeBlock language="json">
          {`{
  "connectionId": "PzIhJfgdjoECG1A=",
  "requestId": "ca1f79b3-ba1c-43cd-b845-364f71b5e6b5",
  "statusCode": 201,
  "data": {
    "time": "2022-04-08T10:27:08.272Z"
 }
}`}
        </CodeBlock>
        <Paragraph>No events are received for cancel call</Paragraph>
      </RightCol>
      {/* <LeftCol span="12">
        <H4 id="create-a-session">Create a session</H4>

        <Row>
          <Col>
            <StyledEventName>create-session</StyledEventName>
          </Col>
          <Col span={18}>
            <P size="small">
              Each elevator call request is linked to a certain WebSocket connection and this link is automatically cut
              off once the connection is closed.
            </P>
            <P size="small">
              Use the <strong>create-session</strong> request to receive a new sessionId needed for reactivating a lost
              connection. Once a new connection is established, create-session or below explained resume-session should
              be the first requests made. Notice that this request is not mandatory. It is possible to make a successful
              lift-call without creating a session.
            </P>
            <UL size="small">
              <li>201 - Session was created</li>
              <li>400 - Invalid request. Either validation error or trying to resume own session</li>
              <li>401 - Not authenticated or token expired</li>
              <li>500 - Internal error</li>
            </UL>
          </Col>
          <Row>
            <Col>
              <PayloadProperty
                name="type"
                type="string"
                required={true}
                description={`Identifies this message as a request to create a session`}
                stringEnums={['create-session']}
              />
              <PayloadProperty
                name="requestId"
                type="string"
                description={`Identifier for the request. The identifier should be unique within one connection. If not provided, one will be generated automatically.`}
              />
            </Col>
          </Row>
        </Row>
      </LeftCol>
      <RightCol span="12">
        <Paragraph>Request example</Paragraph>

        <CodeBlock language="json">
          {`{
  "type": "create-session",
  "requestId": "08c5ff6c-a8fe-405b-bde3-ffcd6935573b"
}`}
        </CodeBlock>
        <Paragraph>Acknowledgement response</Paragraph>

        <CodeBlock language="json">
          {`{
  "type": "ok",
  "connectionId": "ZLOG3c0SDoECFnQ=",
  "requestId": "08c5ff6c-a8fe-405b-bde3-ffcd6935573b",
  "statusCode": 201,
  "timestamp": "2022-02-14T04:53:40.169Z",
  "data": {
    "sessionId": "55bf5b37-e0b8-a2s0-8dcf-dc8c4aefc321"
  }
}`}
        </CodeBlock>

        <Paragraph>Request payload validation error</Paragraph>

        <CodeBlock language="json">
          {`{
  "type": "error",
  "connectionId": "ZLOG3c0SDoECFnQ=",
  "requestId": "08c5ff6c-a8fe-405b-bde3-ffcd6935573b",
  "statusCode": 400,
  "message": "'requestId' cannot exceed 50 characters"
}`}
        </CodeBlock>
      </RightCol>

      <LeftCol span="12">
        <H4 id="resume-a-session">Resume a session</H4>

        <Row>
          <Col>
            <StyledEventName>resume-session</StyledEventName>
          </Col>
          <Col span={18}>
            <P size="small">
              Use the <strong>resume-session</strong> request to reactivate a previously created session. The new
              connection will assume ownership of the previous sessions, active requests and new events related to
              requests made earlier. You can also receive latest state messages relevant to a session.
            </P>
            <P size="small">
              To prevent a potential session theft, authentication token with matching application and user ID (when
              present) are needed when resuming a session. There can only be one active session at once in one
              connection. In case there is an open connection linked to the session, the previous connection is
              immediately closed.
            </P>
            <UL size="small">
              <li>201 - The session has been resumed</li>
              <li>400 - Payload validation error</li>
              <li>401 - Not authenticated or token expired</li>
              <li>404 - Invalid session id</li>
              <li>500 - Internal error</li>
            </UL>
          </Col>
          <Row>
            <Col>
              <PayloadProperty
                name="type"
                type="string"
                required={true}
                description={`Identifies this message as a request to resume a session`}
                stringEnums={['resume-session']}
              />
              <PayloadProperty
                name="requestId"
                type="string"
                description={`Identifier for the request. The identifier should be unique within one connection. If not provided, one will be generated automatically.`}
              />
              <PayloadProperty name="sessionId" type="string" required={true} description={`Session identifier`} />
              <PayloadProperty
                name="resendLatestStateUpToSeconds"
                type="number"
                description={`The time in seconds until the latest state data is re-sent`}
              />
            </Col>
          </Row>
        </Row>
      </LeftCol>
      <RightCol span="12">
        <Paragraph>Request example</Paragraph>

        <CodeBlock language="json">
          {`{
  "type": "resume-session",
  "requestId": "78c5ff6c-a8fe-405b-bde3-ffcd6935573e",
  "sessionId": "55bf5b37-e0b8-a2s0-8dcf-dc8c4aefc321",
  "resendLatestStateUpToSeconds": 30
}`}
        </CodeBlock>

        <Paragraph>Acknowledgement response</Paragraph>

        <CodeBlock language="json">
          {`{
  "type": "ok",
  "connectionId": "ZLOG3c0SDoECFnQ=",
  "timestamp": "2022-02-14T04:53:40.169Z",
  "requestId": "78c5ff6c-a8fe-405b-bde3-ffcd6935573e",
  "statusCode": 201
}`}
        </CodeBlock>

        <Paragraph>Request payload validation error</Paragraph>

        <CodeBlock language="json">
          {`{
  "type": "error",
  "connectionId": "ZLOG3c0SDoECFnQ=",
  "requestId": "78c5ff6c-a8fe-405b-bde3-ffcd6935573e",
  "statusCode": 400,
  "message": "'sessionId' is required"
}`}
        </CodeBlock>
      </RightCol> */}
    </SectionRow>
  )
}
