export const orgTypeLegend = {
  sandbox: 'Sandbox',
  internal: 'Internal',
  external: 'External',
}

export const productLegend = {
  callgiving: 'Elevator Call API',
  robotcall: 'Service Robot API',
  rtm: 'Equipment Status API',
  serviceinfo: 'Service Info API',
}

export const GraphData = {
  ringChartTitle: 'Organization Types',
  productCountTitle: 'Resources - API products',
  orgProductTitle: 'External Partner Count - API Product',
  graphTitle: 'Number of external partners for each API product',
  search: 'Search',
  selectedOrgType: ['external'],
  apiProductType: ['callgiving', 'robotcall', 'rtm', 'serviceinfo'],
  organization: 'Partners',
  apiProduct: 'API Products',
  primaryFilter: 'Select Primary Filter*',
  secondaryFilter: 'Select Secondary Filter',
  viewDetails: 'View Details',
  orgProductDec:
    "This graph gives the total number of external partners using our API product.<br>Please click on the 'View Details' button to see more insights regarding the Partners serving in a particular country with the total number of equipment integrated with the particular API product.",
  orgRingChart: `The ring chart represents the types of organizations we have and their counts.`,
  country: 'Country',
  resourceLocationWiseProduct: 'Location-Wise API Product',
  countryProductDec:
    "This graph displays the total number of resources using the particular API product in a specific country. Currently, it highlights the top 5 countries with the highest API Product usage in terms of number of resources. The 6th bar represents the cumulative count of resources using API products in all the remaining countries. <br>Please click on the 'View Details' button to see more insights.",
  productRingChart: 'The ring chart represents number of resources using the particular API product',
}

export const orgProductDetails = {
  header: 'Product-Wise External Partner Details',
  export: 'Export',
  loadingList: 'Loading Organization Product details list...',
  partnername: 'Partner Name',
  country: 'Country',
  productName: 'Product Name',
  equipmentCount: 'Equipment Count',
}

export const ApiProductMappings: Map<string, string> = new Map([
  ['callgiving', 'Elevator Call API'],
  ['robotcall', 'Service Robot API'],
  ['serviceinfo', 'Service Info API'],
  ['rtm', 'Equipment Status API'],
])

export const headers = [
  { label: 'Partner Name', key: 'partnername' },
  { label: 'Country', key: 'country' },
  { label: 'Product Name', key: 'productname' },
  { label: 'Equipment Count', key: 'equipmentscount' },
]

export const equipmentLocation = [
  { label: 'API Product', key: 'name' },
  { label: 'Resource Id', key: 'equipment_id' },
  { label: 'Resource Type', key: 'resource_type' },
  { label: 'Country', key: 'country' },
  { label: 'City', key: 'city' },
  { label: 'Address line', key: 'address' },
  { label: 'Postal code', key: 'zipcode' },
]

export const equipmentLocationDetails = {
  header: 'Resource Location-Wise API Product Details',
  export: 'Export',
  loadingList: 'Loading Resource Location-Wise Details list...',
  name: 'API Product',
  country: 'Country',
  resource_type: 'Resource Type',
  equipment_id: 'Resource ID',
  address: 'Address Line',
  city: 'City',
  zipcode: 'Postal',
}
