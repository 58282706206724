import React, { FC } from 'react'
import { ToolTipContainer, ToolTipTitle, ToolTipItem, ToolTipColor, ToolTipSeriesName, ToolTipValue } from './Styles'

export type ToolTipParams = {
  axisValue: string
  items: { color: string; seriesName: string; value: number | string }[]
}

const ToolTip: FC<ToolTipParams> = ({ axisValue, items }) => {
  return (
    <ToolTipContainer>
      <ToolTipTitle>{axisValue}</ToolTipTitle>
      {items.map((item, index) => (
        <ToolTipItem key={index}>
          <ToolTipColor color={item.color} />
          <ToolTipSeriesName>{item.seriesName}</ToolTipSeriesName>
          <ToolTipValue>{item.value === 1.5 ? '1' : item.value}</ToolTipValue>
        </ToolTipItem>
      ))}
    </ToolTipContainer>
  )
}

export default ToolTip
